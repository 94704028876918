import MyPipelineToolbarDynamicElement from "@/component/MyPipelines/ToolbarDynamicElement";
import PipelineToolbarDynamicElement from "@/component/Pipeline/ToolbarDynamicElement";
import { CONSTANTS } from "@/util";
import { matchPath, useLocation } from "react-router-dom";
import "./style.less";

export default function DynamicElement({
  className,
}: Readonly<{ className?: string }>) {
  const location = useLocation();

  switch (true) {
    case matchPath(location.pathname, {
      exact: true,
      path: CONSTANTS.ROUTE_PIPELINE,
    }) != null:
      return <PipelineToolbarDynamicElement className={className} />;

    case matchPath(location.pathname, {
      exact: true,
      path: CONSTANTS.ROUTE_MY_PIPELINES,
    }) != null:
      return <MyPipelineToolbarDynamicElement className={className} />;

    default:
      return null;
  }
}
