var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { createValidator } from "../util/createValidator";
import { t } from "../util/i18n";
import { PIPE_CATEGORIES } from "./category";
function sliceString(args, _a) {
    var start = _a.start, end = _a.end;
    return __awaiter(this, void 0, void 0, function () {
        var GraphemeSplitter, clusters;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, import("grapheme-splitter")];
                case 1:
                    GraphemeSplitter = (_b.sent()).default;
                    clusters = new GraphemeSplitter().splitGraphemes(args);
                    return [2 /*return*/, clusters.slice(start, end).join("")];
            }
        });
    });
}
export var PIPES_STRING = {
    "contains-string": {
        category: PIPE_CATEGORIES.String,
        name: t("Contains string"),
        description: t("Check if a string contains another string"),
        requireType: "string",
        returnType: "boolean",
        validateInput: createValidator("stringValidator"),
        defaultAdditionalArgs: { substring: "" },
        fn: function (args, _a) {
            var substring = _a.substring;
            return args.includes(substring);
        },
    },
    "get-first-n-string-characters": {
        category: PIPE_CATEGORIES.String,
        name: t("Get first n string characters"),
        description: t("Get the first n characters of a string"),
        requireType: "string",
        returnType: "string",
        validateInput: createValidator("stringValidator"),
        defaultAdditionalArgs: { numberOfCharacters: 10 },
        fn: function (args, _a) {
            var numberOfCharacters = _a.numberOfCharacters;
            return sliceString(args, { start: 0, end: numberOfCharacters });
        },
    },
    "get-last-n-string-characters": {
        category: PIPE_CATEGORIES.String,
        name: t("Get last n string characters"),
        description: t("Get the last n characters of a string"),
        requireType: "string",
        returnType: "string",
        validateInput: createValidator("stringValidator"),
        defaultAdditionalArgs: { numberOfCharacters: 10 },
        fn: function (args, _a) {
            var numberOfCharacters = _a.numberOfCharacters;
            return sliceString(args, { start: -numberOfCharacters });
        },
    },
    "get-string-length": {
        category: PIPE_CATEGORIES.String,
        name: t("Get string length"),
        description: t("Get the length of a string"),
        requireType: "string",
        returnType: "number",
        validateInput: createValidator("stringValidator"),
        fn: function (args) {
            return args.length;
        },
    },
    "postfix-string": {
        category: PIPE_CATEGORIES.String,
        name: t("Postfix string"),
        description: t("Postfix a string with another string"),
        requireType: "string",
        returnType: "string",
        validateInput: createValidator("stringValidator"),
        defaultAdditionalArgs: { postfix: "" },
        fn: function (args, _a) {
            var postfix = _a.postfix;
            return "".concat(args).concat(postfix);
        },
    },
    "prefix-string": {
        category: PIPE_CATEGORIES.String,
        name: t("Prefix string"),
        description: t("Prefix a string with another string"),
        requireType: "string",
        returnType: "string",
        validateInput: createValidator("stringValidator"),
        defaultAdditionalArgs: { prefix: "" },
        fn: function (args, _a) {
            var prefix = _a.prefix;
            return "".concat(prefix).concat(args);
        },
    },
    "slice-string": {
        category: PIPE_CATEGORIES.String,
        name: t("Slice string"),
        description: t([
            "Get a sub-string from a string using start (inclusive) and end ",
            "(exclusive) indexes",
        ].join("")),
        requireType: "string",
        returnType: "string",
        validateInput: createValidator("stringValidator"),
        defaultAdditionalArgs: { start: 0, end: -1 },
        fn: function (args, _a) {
            var start = _a.start, end = _a.end;
            return __awaiter(void 0, void 0, void 0, function () {
                return __generator(this, function (_b) {
                    return [2 /*return*/, sliceString(args, { start: start, end: end })];
                });
            });
        },
    },
    "split-string": {
        category: PIPE_CATEGORIES.String,
        name: t("Split string"),
        description: t("Split a string into an Array using a separator"),
        requireType: "string",
        returnType: "array",
        validateInput: createValidator("stringValidator"),
        defaultAdditionalArgs: { separator: "" },
        fn: function (args, _a) {
            var separator = _a.separator;
            return args.split(separator);
        },
    },
    "to-lowercase": {
        category: PIPE_CATEGORIES.String,
        name: t("To lowercase"),
        description: t("Convert a string into its lowercased form"),
        requireType: "string",
        returnType: "string",
        validateInput: createValidator("stringValidator"),
        fn: function (args) {
            return args.toLowerCase();
        },
    },
    "to-uppercase": {
        category: PIPE_CATEGORIES.String,
        name: t("To uppercase"),
        description: t("Convert a string into its uppercased form"),
        requireType: "string",
        returnType: "string",
        validateInput: createValidator("stringValidator"),
        fn: function (args) {
            return args.toUpperCase();
        },
    },
    "trim-string": {
        category: PIPE_CATEGORIES.String,
        name: t("Trim string"),
        description: t("Trim leading and trailing whitespaces from a string"),
        requireType: "string",
        returnType: "string",
        validateInput: createValidator("stringValidator"),
        fn: function (args) {
            return args.trim();
        },
    },
};
