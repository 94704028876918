import { IAppTheme } from "@/interface";
import { defaultAppTheme } from "@/util";
import { ScreenBreakpoint } from "@misc-chatbot/common-ui";
import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

export interface IAppState {
  readonly currentTheme: IAppTheme;
  readonly featureToggle: Readonly<{}>;
  readonly isDrawerOpen: boolean;
  readonly isPopconfirmVisible: boolean;
  readonly screenBreakpoint: ScreenBreakpoint;
}

const initialState: IAppState = {
  currentTheme: defaultAppTheme,
  featureToggle: {},
  isDrawerOpen: false,
  isPopconfirmVisible: false,
  screenBreakpoint: ScreenBreakpoint.Undetermined,
};

export const appSlice = createSlice({
  initialState,
  name: "app",
  reducers: {
    noop: () => {},
    setCurrentTheme: (
      state,
      action: PayloadAction<IAppState["currentTheme"]>
    ) => {
      state.currentTheme = action.payload;
    },
    setIsDrawerOpen: (state, action: PayloadAction<boolean>) => {
      state.isDrawerOpen = action.payload;
    },
    setIsPopconfirmVisible: (state, action: PayloadAction<boolean>) => {
      state.isPopconfirmVisible = action.payload;
    },
    setScreenBreakpoint: (
      state,
      action: PayloadAction<IAppState["screenBreakpoint"]>
    ) => {
      state.screenBreakpoint = action.payload;
    },
    toggleIsDrawerOpen: (state) => {
      state.isDrawerOpen = !state.isDrawerOpen;
    },

    triggerError: (_state, _action: PayloadAction<unknown>) => {},
    triggerMessage: (_state, _action: PayloadAction<string>) => {},
    triggerMultiActions: (
      _state,
      _action: PayloadAction<readonly PayloadAction<unknown>[]>
    ) => {},
  },
});

export const appActions = appSlice.actions;
export default appSlice.reducer;
