export function requireNotNull(value, message) {
    if (message === void 0) { message = "Unexpected null value \"".concat(value, "\""); }
    if (value == null) {
        throw new Error(message);
    }
    return value;
}
export function requireTruthy(value, message) {
    if (message === void 0) { message = "Unexpected falsy value \"".concat(value, "\""); }
    if (!value) {
        throw new Error(message);
    }
    return value;
}
