var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { createValidator } from "../util/createValidator";
import { t } from "../util/i18n";
import { PIPE_CATEGORIES } from "./category";
export var PIPES_ARRAY = {
    "access-array-index": {
        category: PIPE_CATEGORIES.Array,
        name: t("Access array index"),
        description: t("Access an Array element at a specified index"),
        requireType: "array",
        returnType: "any",
        validateInput: createValidator("arrayValidator"),
        defaultAdditionalArgs: { index: 0 },
        fn: function (args, _a) {
            var index = _a.index;
            return args.at(index);
        },
    },
    "chunk-array": {
        category: PIPE_CATEGORIES.Array,
        name: t("Chunk array"),
        description: t("Chunk an Array into multiple smaller Arrays of a specified size"),
        requireType: "array",
        returnType: "array",
        validateInput: createValidator("arrayValidator"),
        defaultAdditionalArgs: { chunkSize: 1 },
        fn: function (args, _a) {
            var chunkSize = _a.chunkSize;
            return __awaiter(void 0, void 0, void 0, function () {
                var chunk;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0: return [4 /*yield*/, import("lodash-es")];
                        case 1:
                            chunk = (_b.sent()).chunk;
                            return [2 /*return*/, chunk(args, chunkSize)];
                    }
                });
            });
        },
    },
    "flatten-array": {
        category: PIPE_CATEGORIES.Array,
        name: t("Flatten array"),
        description: t("Deeply flatten an Array of potentially multiple sub-Arrays into one Array"),
        requireType: "array",
        returnType: "array",
        validateInput: createValidator("arrayValidator"),
        fn: function (args) { return __awaiter(void 0, void 0, void 0, function () {
            var flattenDeep;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, import("lodash-es")];
                    case 1:
                        flattenDeep = (_a.sent()).flattenDeep;
                        return [2 /*return*/, flattenDeep(args)];
                }
            });
        }); },
    },
    "get-array-length": {
        category: PIPE_CATEGORIES.Array,
        name: t("Get array length"),
        description: t("Get the length of an Array"),
        requireType: "array",
        returnType: "number",
        validateInput: createValidator("arrayValidator"),
        fn: function (args) {
            return args.length;
        },
    },
    "get-first-n-array-elements": {
        category: PIPE_CATEGORIES.Array,
        name: t("Get first n array elements"),
        description: t("Get the first n elements of an Array"),
        requireType: "array",
        returnType: "array",
        validateInput: createValidator("arrayValidator"),
        defaultAdditionalArgs: { numberOfElements: 10 },
        fn: function (args, _a) {
            var numberOfElements = _a.numberOfElements;
            return args.slice(0, numberOfElements);
        },
    },
    "get-last-n-array-elements": {
        category: PIPE_CATEGORIES.Array,
        name: t("Get last n array elements"),
        description: t("Get the last n elements of an Array"),
        requireType: "array",
        returnType: "array",
        validateInput: createValidator("arrayValidator"),
        defaultAdditionalArgs: { numberOfElements: 10 },
        fn: function (args, _a) {
            var numberOfElements = _a.numberOfElements;
            return args.slice(-numberOfElements);
        },
    },
    "get-property-each-array-element": {
        category: PIPE_CATEGORIES.Array,
        name: t("Get property from each element"),
        description: t([
            "For each element in an Array, get a property at a specified key, ",
            "then return an Array of those property values. Nested property syntax ",
            'such as "a.b[0].c.d" is supported. For example, if the key is "a" ',
            'and the Array is "[{ a: 1 }, { a: 2 }]", the final result would be ',
            "[1, 2].",
        ].join("")),
        requireType: "array",
        returnType: "array",
        validateInput: createValidator("arrayValidator"),
        defaultAdditionalArgs: { key: "" },
        fn: function (args, _a) {
            var key = _a.key;
            return __awaiter(void 0, void 0, void 0, function () {
                var lodashGet;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0: return [4 /*yield*/, import("lodash-es")];
                        case 1:
                            lodashGet = (_b.sent()).get;
                            if (!key) {
                                return [2 /*return*/, args];
                            }
                            return [2 /*return*/, args.map(function (element) {
                                    return lodashGet(element, key);
                                })];
                    }
                });
            });
        },
    },
    "group-array-by-key": {
        category: PIPE_CATEGORIES.Array,
        name: t("Group array by key"),
        description: t([
            "Group an Array of objects by a specific object key and return the ",
            "grouped object",
        ].join("")),
        requireType: "array",
        returnType: "object",
        validateInput: createValidator("arrayValidator"),
        defaultAdditionalArgs: { key: "" },
        fn: function (args, _a) {
            var key = _a.key;
            return __awaiter(void 0, void 0, void 0, function () {
                var groupBy;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0: return [4 /*yield*/, import("lodash-es")];
                        case 1:
                            groupBy = (_b.sent()).groupBy;
                            return [2 /*return*/, groupBy(args, key)];
                    }
                });
            });
        },
    },
    "join-array": {
        category: PIPE_CATEGORIES.Array,
        name: t("Join array"),
        description: t("Join array elements into a string using a separator"),
        requireType: "array",
        returnType: "string",
        validateInput: createValidator("arrayValidator"),
        defaultAdditionalArgs: { separator: "" },
        fn: function (args, _a) {
            var separator = _a.separator;
            return args.join(separator);
        },
    },
    "remove-duplicate-array-elements": {
        category: PIPE_CATEGORIES.Array,
        name: t("Remove duplicate Array elements"),
        description: t([
            "Remove duplicate elements in an Array using SameValueZero comparison",
        ].join("")),
        requireType: "array",
        returnType: "array",
        validateInput: createValidator("arrayValidator"),
        fn: function (args) { return __awaiter(void 0, void 0, void 0, function () {
            var uniq;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, import("lodash-es")];
                    case 1:
                        uniq = (_a.sent()).uniq;
                        return [2 /*return*/, uniq(args)];
                }
            });
        }); },
    },
    "reverse-array": {
        category: PIPE_CATEGORIES.Array,
        name: t("Reverse array"),
        description: t("Reverse an Array"),
        requireType: "array",
        returnType: "array",
        validateInput: createValidator("arrayValidator"),
        fn: function (args) {
            return args.slice().reverse();
        },
    },
    "slice-array": {
        category: PIPE_CATEGORIES.Array,
        name: t("Slice array"),
        description: t("Get a sub-array from an Array using start (inclusive) and end (exclusive) indexes"),
        requireType: "array",
        returnType: "array",
        validateInput: createValidator("arrayValidator"),
        defaultAdditionalArgs: { start: 0, end: -1 },
        fn: function (args, _a) {
            var start = _a.start, end = _a.end;
            return args.slice(start, end);
        },
    },
};
