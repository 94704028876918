import type { IPipelineState } from "@/dependency/store";
import type {
  IPipelineWithTestInput,
  ISpecialResultConfiguration,
} from "@/interface";
import { t } from "@/util";
import { keyof } from "@haipham/javascript-helper-object";
import type {
  IEvaluationResult,
  IPipeByType,
  IPipeType,
  IPipeWithUIStates,
  IReturnType,
} from "@swissknife/ui-sdk";
import type { PresetColorType } from "antd/es/_util/colors";
import { isObject } from "lodash-es";

export const FORM_ITEM_ACTIVE_PIPE_RESULT_INDEX = keyof<IPipelineState>(
  "activePipeResultIndex"
);
export const FORM_ITEM_PIPELINE_NAME = keyof<IPipelineState>("pipelineName");
export const FORM_ITEM_PIPELINE_RENDER_PREVIEW =
  keyof<IPipelineState>("renderPreview");
export const FORM_ITEM_PIPELINE_TEST_INPUT = keyof<IPipelineState>("testInput");
export const FORM_ITEM_PIPELINE_WRAP_FINAL_RESULT_CONTENT =
  keyof<IPipelineState>("wrapFinalResultContent");

interface IAdditionalArgsFormItemName {
  <PipeType extends IPipeType>(
    ...args: Required<IPipeByType[PipeType]>["additionalArgs"] extends undefined
      ? [PipeType, IPipeWithUIStates["id"]]
      : [
          PipeType,
          IPipeWithUIStates["id"],
          keyof Required<IPipeByType[PipeType]>["additionalArgs"]
        ]
  ): string;
}

export const additionalArgsFormItemName = ((
  ...[, pipeID, additionalArgsKey]
) => {
  return [pipeID, keyof<IPipeWithUIStates>("additionalArgs"), additionalArgsKey]
    .filter(Boolean)
    .join(".");
}) as IAdditionalArgsFormItemName;

export function arbitraryAdditionalArgsFormItemName(
  pipeID: IPipeWithUIStates["id"],
  additionalArgsKey: string
): string {
  return [pipeID, additionalArgsKey].filter(Boolean).join(".");
}

export function getDisplayModeDescription(
  displayMode: IPipelineWithTestInput["displayMode"]
): string {
  switch (displayMode) {
    case "compact":
      return t("Compact display");

    case "full":
      return t("Full display");
  }
}

export function getSpecialResultTypeTagColor(
  args: ISpecialResultConfiguration["type"]
): PresetColorType {
  switch (args) {
    case "document-diff":
      return "blue";

    case "coordinates":
      return "cyan";

    case "html":
      return "geekblue";
  }
}

export function getStandardReturnType(args: IEvaluationResult): IReturnType {
  if (args.type === "error") {
    return "error";
  } else if (args.data == null) {
    return "null";
  } else if (Array.isArray(args.data)) {
    return "array";
  } else if (args.data instanceof Date) {
    return "date";
  } else if (isObject(args.data)) {
    return "object";
  } else if (typeof args.data === "boolean") {
    return "boolean";
  } else if (typeof args.data === "number") {
    return "number";
  } else if (typeof args.data === "string") {
    return "string";
  } else {
    return "any";
  }
}

export function isValidDisplayMode(
  displayMode: unknown
): displayMode is IPipelineWithTestInput["displayMode"] {
  switch (displayMode as IPipelineWithTestInput["displayMode"]) {
    case "compact":
    case "full":
      return true;
  }
}
