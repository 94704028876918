import { createValidator } from "../util/createValidator";
import { t } from "../util/i18n";
import { PIPE_CATEGORIES } from "./category";
export var PIPES_NUMBER = {
    "bigint-to-integer": {
        category: PIPE_CATEGORIES.Number,
        name: t("BigInt to integer"),
        description: t("Convert a BigInteger to an integer"),
        requireType: "bigint",
        returnType: "number",
        validateInput: createValidator("bigintValidator"),
        fn: function (args) {
            return parseInt(args.toString(), 10);
        },
    },
    "integer-to-bigint": {
        category: PIPE_CATEGORIES.Number,
        name: t("Integer to BigInt"),
        description: t("Convert an integr o a BigInt"),
        requireType: "number",
        returnType: "bigint",
        validateInput: createValidator("numberValidator"),
        fn: function (args) {
            return BigInt(args);
        },
    },
    "is-integer": {
        category: PIPE_CATEGORIES.Number,
        name: t("Is integer"),
        description: t("Check if a number is an integer"),
        requireType: "number",
        returnType: "boolean",
        validateInput: createValidator("numberValidator"),
        fn: function (args) {
            return Number.isInteger(args);
        },
    },
    "number-to-string": {
        category: PIPE_CATEGORIES.Number,
        name: t("Number to string"),
        description: t("Convert a number to string with a specified radix"),
        requireType: "number",
        returnType: "string",
        validateInput: createValidator("numberValidator"),
        defaultAdditionalArgs: { radix: 10 },
        fn: function (args, _a) {
            var radix = _a.radix;
            return args.toString(radix);
        },
    },
    "string-to-bigint": {
        category: PIPE_CATEGORIES.Number,
        name: t("String to BigInt"),
        description: t("Convert a string into a BigInt"),
        requireType: "string",
        returnType: "bigint",
        validateInput: createValidator("numberCompatibleStringValidator"),
        fn: function (args) {
            return BigInt(args);
        },
    },
    "string-to-float": {
        category: PIPE_CATEGORIES.Number,
        name: t("String to float"),
        description: t("Convert a string into a floating-point number"),
        requireType: "string",
        returnType: "number",
        validateInput: createValidator("numberCompatibleStringValidator"),
        fn: function (args) {
            return parseFloat(args);
        },
    },
    "string-to-integer": {
        category: PIPE_CATEGORIES.Number,
        name: t("String to integer"),
        description: t("Convert a string into an integer"),
        requireType: "string",
        returnType: "number",
        validateInput: createValidator("numberCompatibleStringValidator"),
        fn: function (args) {
            return parseInt(args, 10);
        },
    },
};
