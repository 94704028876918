import { IAppTheme } from "@/interface";
import { CONSTANTS } from "./constant";

export function isValidAppTheme(theme: unknown): theme is IAppTheme {
  switch (theme as IAppTheme) {
    case "dark":
    case "light": {
      return true;
    }
  }
}

function getStoredAppTheme(): IAppTheme {
  let storedAppTheme: string | null | undefined;

  if (global.window != null) {
    storedAppTheme = global.window.localStorage.getItem(
      CONSTANTS.KEY_STORAGE_APP_THEME
    );
  }

  /**
   * Somehow the theme value is stored with double quotes (e.g. "light"), so we
   * will need to strip those quotes out.
   */
  const theme = storedAppTheme?.match(/"?(?<theme>[A-Za-z0-9]+)"?/)?.groups
    ?.theme;

  if (!isValidAppTheme(theme)) {
    return CONSTANTS.THEME_DEFAULT;
  }

  return theme;
}

export const defaultAppTheme: IAppTheme =
  getStoredAppTheme() || CONSTANTS.THEME_DEFAULT;
