import TutorialView, { ITutorial } from "@/component/TutorialView";
import { appActions, useAppSelector } from "@/dependency/store";
import { IAppTheme } from "@/interface";
import { CONSTANTS, t } from "@/util";
import {
  BugOutlined,
  BuildOutlined,
  DatabaseOutlined,
  ExportOutlined,
  ImportOutlined,
  PlusCircleOutlined,
  PlusOutlined,
  StarOutlined,
} from "@ant-design/icons";
import { useLocalStorageState } from "ahooks";
import { Drawer, Menu, Switch } from "antd";
import { useState } from "react";
import { useThemeSwitcher } from "react-css-theme-switcher";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import "./style.less";

const CLASS_BLOCK = "drawer-container";
const CLASS_ELEMENT_THEME_SWITCH = `${CLASS_BLOCK}__theme-switch`;

const TUTORIALS: ITutorial[] = [
  {
    message: t("Click here to switch between light and dark themes."),
    querySelector: `.${CLASS_ELEMENT_THEME_SWITCH}`,
    placement: "right",
  },
];

export default function CustomDrawer() {
  const dispatch = useDispatch();

  const isDrawerOpen = useAppSelector(({ app }) => {
    return app.isDrawerOpen;
  });

  const { currentTheme, switcher: switchTheme } = useThemeSwitcher();

  const [, setLocalStorageAppTheme] = useLocalStorageState(
    CONSTANTS.KEY_STORAGE_APP_THEME
  );

  /**
   * We need to track when the drawer is fully visible, otherwise the tutorials
   * may appear too soon.
   */
  const [isFullyVisible, setIsFullyVisible] = useState(false);

  return (
    <Drawer
      className={CLASS_BLOCK}
      open={isDrawerOpen}
      placement="left"
      visible={isDrawerOpen}
      afterVisibleChange={(fullyVisible) => {
        setIsFullyVisible(fullyVisible);
      }}
      onClose={(event) => {
        event.stopPropagation();
        dispatch(appActions.setIsDrawerOpen(false));
      }}
    >
      <div className={`${CLASS_BLOCK}__content`}>
        <Menu
          className={`${CLASS_BLOCK}__menu_theme-${currentTheme}`}
          items={[
            {
              key: "new-pipeline",
              icon: <PlusOutlined />,
              label: (
                <Link replace to={CONSTANTS.ROUTE_NEW_PIPELINE}>
                  {t("New pipeline")}
                </Link>
              ),
            },
            {
              key: "new-sample-pipeline",
              icon: <PlusCircleOutlined />,
              label: (
                <Link
                  replace
                  to={`${CONSTANTS.ROUTE_NEW_PIPELINE}?${CONSTANTS.QUERY_PARAM_PIPELINE_REDIRECT_SAMPLE}=true`}
                >
                  {t("New sample pipeline")}
                </Link>
              ),
            },
            {
              key: "my-pipelines",
              icon: <DatabaseOutlined />,
              label: (
                <Link replace to={CONSTANTS.ROUTE_MY_PIPELINES}>
                  {t("My pipelines")}
                </Link>
              ),
            },
            {
              key: "my-favorite-pipelines",
              icon: <StarOutlined />,
              label: (
                <Link
                  replace
                  to={`${CONSTANTS.ROUTE_MY_PIPELINES}?${CONSTANTS.QUERY_PARAM_MY_PIPELINES_ONLY_FAVORITES}=true`}
                >
                  {t("My favorites")}
                </Link>
              ),
            },
            {
              key: "import-pipelines",
              icon: <ImportOutlined />,
              label: (
                <Link
                  replace
                  to={`${CONSTANTS.ROUTE_MY_PIPELINES}?${CONSTANTS.QUERY_PARAM_MY_PIPELINES_IS_IMPORTING}=true`}
                >
                  {t("Import pipelines")}
                </Link>
              ),
            },
            {
              key: "export-pipelines",
              icon: <ExportOutlined />,
              label: (
                <Link
                  replace
                  to={`${CONSTANTS.ROUTE_MY_PIPELINES}?${CONSTANTS.QUERY_PARAM_MY_PIPELINES_IS_EXPORTING}=true`}
                >
                  {t("Export pipelines")}
                </Link>
              ),
            },
            {
              key: "quick-javascript-runtime",
              icon: <BuildOutlined />,
              label: (
                <Link replace to={CONSTANTS.ROUTE_QUICK_JAVASCRIPT_RUNTIME}>
                  {t("Quick JavaScript runtime")}
                </Link>
              ),
            },
            {
              key: "send-feedback",
              icon: <BugOutlined />,
              label: (
                <a
                  href={process.env.FEEDBACK_ISSUE_URL}
                  rel="noreferrer"
                  target="_blank"
                >
                  {t("Send a feedback")}
                </a>
              ),
            },
          ]}
          onClick={() => {
            dispatch(appActions.setIsDrawerOpen(false));
          }}
        />

        <Switch
          className={CLASS_ELEMENT_THEME_SWITCH}
          defaultChecked={currentTheme === "dark"}
          checkedChildren={t("Dark mode")}
          unCheckedChildren={t("Light mode")}
          onChange={(isDarkMode) => {
            const appTheme: IAppTheme = isDarkMode ? "dark" : "light";
            setLocalStorageAppTheme(appTheme);
            switchTheme({ theme: appTheme });
          }}
        />

        <TutorialView
          delayMs={CONSTANTS.DURATION_TUTORIAL_DELAY_MS}
          persistenceKey={
            CONSTANTS.TUTORIAL.KEY_STORAGE_HAS_VIEWED_DRAWER_TUTORIALS
          }
          tutorials={TUTORIALS}
          visible={isFullyVisible}
        />
      </div>
    </Drawer>
  );
}
