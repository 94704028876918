import { scrollbarWidth as getScrollbarWidth } from "@xobotyi/scrollbar-width";
import { useEffect, useMemo, useState } from "react";
import useResizeObserver from "use-resize-observer";

export function useScrollbarWidth() {
  const width = useMemo(() => {
    return getScrollbarWidth() || 0;
  }, []);

  return width;
}

export function useAntdTableBodyDimensions({
  dependencies = [],
  size = "small",
  headerHeight = (() => {
    switch (size) {
      case "small":
        return 40;

      case "middle":
        return 48;

      case "large":
        return 56;
    }
  })(),
}: Readonly<{
  dependencies?: readonly unknown[];
  headerHeight?: number;
  size?: import("antd/lib/config-provider/SizeContext").SizeType;
}> = {}) {
  const scrollbarWidth = useScrollbarWidth();

  const {
    ref: tableRef,
    height: baseHeight,
    width: baseWidth,
  } = useResizeObserver<HTMLDivElement>();

  const bodyDimensions = useMemo(() => {
    let _bodyDimensions:
      | Readonly<{ height: number; width: number }>
      | undefined = undefined;

    if (baseHeight != null && baseWidth != null) {
      let paginationHeight: number;
      let paginationMargin: number;

      switch (size) {
        case "small":
        case "middle": {
          paginationHeight = 24;
          paginationMargin = 16;
          break;
        }

        case "large": {
          paginationHeight = 32;
          paginationMargin = 16;
          break;
        }
      }

      _bodyDimensions = {
        height:
          baseHeight - headerHeight - paginationHeight - paginationMargin * 2,
        width: baseWidth - scrollbarWidth,
      };
    }

    return _bodyDimensions;
  }, [baseHeight, baseWidth, scrollbarWidth, ...dependencies]);

  return {
    tableRef,
    bodyHeight: bodyDimensions?.height,
    bodyWidth: bodyDimensions?.width,
    tableSize: size,
  };
}

export function useWindowSize(): Readonly<
  { height: number; width: number } | undefined
> {
  const [windowSize, setWindowSize] = useState<
    Readonly<{ height: number; width: number }> | undefined
  >(undefined);

  useEffect(() => {
    if (global.window == null) {
      return;
    }

    const handleResize = () => {
      setWindowSize({ width: window.innerWidth, height: window.innerHeight });
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [setWindowSize]);

  return windowSize;
}

export enum ScreenBreakpoint {
  ForPhoneOnly = 0,
  ForTablePortraitUp = 1,
  ForTableLandscapeUp = 2,
  ForDesktopUp = 3,
  ForBigDesktopUp = 4,
  Undetermined = Infinity,
}

export function useScreenBreakpoint(): ScreenBreakpoint {
  const windowSize = useWindowSize();

  const breakpoint = useMemo((): ScreenBreakpoint => {
    if (windowSize == null) {
      return ScreenBreakpoint.Undetermined;
    }

    switch (true) {
      case windowSize.width >= 1800: {
        return ScreenBreakpoint.ForBigDesktopUp;
      }

      case windowSize.width >= 1200: {
        return ScreenBreakpoint.ForDesktopUp;
      }

      case windowSize.width >= 900: {
        return ScreenBreakpoint.ForTableLandscapeUp;
      }

      case windowSize.width > 600: {
        return ScreenBreakpoint.ForTableLandscapeUp;
      }

      default: {
        return ScreenBreakpoint.ForPhoneOnly;
      }
    }
  }, [windowSize]);

  return breakpoint;
}
