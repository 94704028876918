import { IPipeInputGenerator, IPipeInputGeneratorByType } from "@/interface";
import dayjs from "dayjs";
import { t } from "./i18n";

export const PIPE_INPUT_GENERATORS_BY_TYPE: IPipeInputGeneratorByType = {
  "current-date-iso-string": {
    category: "Date",
    name: t("Current date ISO string"),
    returnType: "string",
    fn: () => {
      return dayjs().toISOString();
    },
  },
};

export const PIPE_INPUT_GENERATORS = Object.entries(
  PIPE_INPUT_GENERATORS_BY_TYPE
).map(([generatorType, inputGenerator]) => {
  return { ...inputGenerator, type: generatorType };
}) as readonly IPipeInputGenerator[];
