var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { SDK_CONSTANTS } from "../util/constant";
import { createValidator } from "../util/createValidator";
import { t } from "../util/i18n";
import { PIPE_CATEGORIES } from "./category";
export var PIPES_DATE = {
    "date-to-iso-string": {
        category: PIPE_CATEGORIES.Date,
        description: t("Convert a Date to an ISO string"),
        name: t("Date to ISO string"),
        requireType: "date",
        returnType: "string",
        validateInput: createValidator("dateValidator"),
        fn: function (args) { return __awaiter(void 0, void 0, void 0, function () {
            var dayjs;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, import("dayjs")];
                    case 1:
                        dayjs = (_a.sent()).default;
                        return [2 /*return*/, dayjs(args).toISOString()];
                }
            });
        }); },
    },
    "date-to-string": {
        category: PIPE_CATEGORIES.Date,
        name: t("Date to string"),
        description: t("Convert a Date into a string"),
        requireType: "date",
        returnType: "string",
        validateInput: createValidator("dateValidator"),
        defaultAdditionalArgs: { format: SDK_CONSTANTS.FORMAT_DAYJS_DEFAULT },
        fn: function (args, _a) {
            var format = _a.format;
            return __awaiter(void 0, void 0, void 0, function () {
                var dayjs;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0: return [4 /*yield*/, import("dayjs")];
                        case 1:
                            dayjs = (_b.sent()).default;
                            return [2 /*return*/, dayjs(args).format(format)];
                    }
                });
            });
        },
    },
    "epoch-milliseconds-to-date": {
        category: PIPE_CATEGORIES.Date,
        name: t("Epoch milliseconds to Date"),
        description: t("Convert a number of milliseconds since epoch to a Date"),
        requireType: "number",
        returnType: "date",
        validateInput: createValidator("numberValidator"),
        fn: function (args) { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, new Date(args)];
            });
        }); },
    },
    "get-current-date": {
        category: PIPE_CATEGORIES.Date,
        name: t("Get the current date"),
        description: t("Get the current date"),
        requireType: "nothing",
        returnType: "date",
        fn: function () { return __awaiter(void 0, void 0, void 0, function () {
            var dayjs;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, import("dayjs")];
                    case 1:
                        dayjs = (_a.sent()).default;
                        return [2 /*return*/, dayjs().toDate()];
                }
            });
        }); },
    },
    "get-current-date-iso-string": {
        category: PIPE_CATEGORIES.Date,
        name: t("Get the current date ISO string"),
        description: t("Get the current date ISO string"),
        requireType: "nothing",
        returnType: "string",
        fn: function () { return __awaiter(void 0, void 0, void 0, function () {
            var dayjs;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, import("dayjs")];
                    case 1:
                        dayjs = (_a.sent()).default;
                        return [2 /*return*/, dayjs().toISOString()];
                }
            });
        }); },
    },
    "iso-string-to-date": {
        category: PIPE_CATEGORIES.Date,
        name: t("ISO string to Date"),
        description: t("Convert an ISO string to a Date"),
        requireType: "string",
        returnType: "date",
        validateInput: createValidator("stringValidator"),
        fn: function (args) { return __awaiter(void 0, void 0, void 0, function () {
            var dayjs;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, import("dayjs")];
                    case 1:
                        dayjs = (_a.sent()).default;
                        return [2 /*return*/, dayjs(args).toDate()];
                }
            });
        }); },
    },
    "modify-date": {
        category: PIPE_CATEGORIES.Date,
        description: t("Modify a date by adding to or subtracting from it using a certain date unit"),
        name: t("Modify date"),
        requireType: "date",
        returnType: "date",
        validateInput: createValidator("dateValidator"),
        defaultAdditionalArgs: { operation: "add", unit: "millisecond", value: 0 },
        fn: function (args, _a) {
            var operation = _a.operation, unit = _a.unit, value = _a.value;
            return __awaiter(void 0, void 0, void 0, function () {
                var dayjs;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0: return [4 /*yield*/, import("dayjs")];
                        case 1:
                            dayjs = (_b.sent()).default;
                            switch (operation) {
                                case "add": {
                                    return [2 /*return*/, dayjs(args).add(value, unit).toDate()];
                                }
                                case "subtract":
                                    return [2 /*return*/, dayjs(args).subtract(value, unit).toDate()];
                            }
                            return [2 /*return*/];
                    }
                });
            });
        },
    },
    "string-to-date": {
        category: PIPE_CATEGORIES.Date,
        name: t("String to Date"),
        description: t("Convert a string into a Date"),
        requireType: "string",
        returnType: "date",
        validateInput: createValidator("stringValidator"),
        defaultAdditionalArgs: { format: SDK_CONSTANTS.FORMAT_DAYJS_DEFAULT },
        fn: function (args, _a) {
            var format = _a.format;
            return __awaiter(void 0, void 0, void 0, function () {
                var dayjs, parsedDayjs;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0: return [4 /*yield*/, import("dayjs")];
                        case 1:
                            dayjs = (_b.sent()).default;
                            parsedDayjs = dayjs(args, format, true);
                            if (!parsedDayjs.isValid()) {
                                throw new Error("Invalid date");
                            }
                            return [2 /*return*/, parsedDayjs.toDate()];
                    }
                });
            });
        },
    },
};
