export const SDK_CONSTANTS = {
  DOCKER_REGISTRY_DEFAULT: "docker.io",

  DURATION_INTERVAL_MS_DEFAULT: 1000,

  FORMAT_DAYJS_DEFAULT: "DD/MM/YYYY",

  KEYWORD_EVAL_INPUT: "$input",

  INDENTATION_COUNT_DEFAULT: 2,

  PRINT_WIDTH_DEFAULT: 80,
};
