import { createValidator } from "../util/createValidator";
import { t } from "../util/i18n";
import { PIPE_CATEGORIES } from "./category";
export var PIPES_BOOLEAN = {
    if: {
        category: PIPE_CATEGORIES.Boolean,
        name: t("If true or false"),
        description: t("Return different values if the input is true or false"),
        requireType: "boolean",
        returnType: "string",
        validateInput: createValidator("booleanValidator"),
        defaultAdditionalArgs: { true: "", false: "" },
        fn: function (args, additionalArgs) {
            if (args) {
                return additionalArgs.true;
            }
            return additionalArgs.false;
        },
    },
};
