var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { SDK_CONSTANTS } from "../util/constant";
import { createValidator } from "../util/createValidator";
import { t } from "../util/i18n";
import { PIPE_CATEGORIES } from "./category";
export var PIPES_TYPESCRIPT = {
    "compile-typescript-with-babel": {
        category: [
            PIPE_CATEGORIES["Programming Language"],
            PIPE_CATEGORIES.Typescript,
        ],
        name: t("Compile Typescript with Babel"),
        description: t("Compile Typescript code into ECMAScript Javascript using Babel."),
        requireType: "string",
        returnType: "string",
        validateInput: createValidator("stringValidator"),
        fn: function (args) { return __awaiter(void 0, void 0, void 0, function () {
            var transform, code;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, import("@babel/standalone")];
                    case 1:
                        transform = (_a.sent()).transform;
                        code = transform(args, {
                            filename: "file.ts",
                            presets: ["typescript"],
                        }).code;
                        /* istanbuil ignore if */
                        if (!code) {
                            throw new Error("Unable to transform Typescript code");
                        }
                        return [2 /*return*/, code];
                }
            });
        }); },
    },
    "new-tsconfig-json": {
        category: [
            PIPE_CATEGORIES["Programming Language"],
            PIPE_CATEGORIES.Typescript,
        ],
        name: t("New tsconfig.json"),
        description: t("Create a new tsconfig.json file"),
        requireType: "nothing",
        returnType: "string",
        fn: function () { return __awaiter(void 0, void 0, void 0, function () {
            var tsConfig;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, import("../template/newTSConfig")];
                    case 1:
                        tsConfig = (_a.sent()).default;
                        return [2 /*return*/, tsConfig];
                }
            });
        }); },
    },
    "prettify-typescript": {
        category: [
            PIPE_CATEGORIES["Programming Language"],
            PIPE_CATEGORIES.Typescript,
        ],
        name: t("Prettify Typescript"),
        description: t("Prettify Typescript code"),
        requireType: "string",
        returnType: "string",
        validateInput: createValidator("stringValidator"),
        defaultAdditionalArgs: {
            indentation: SDK_CONSTANTS.INDENTATION_COUNT_DEFAULT,
            printWidth: SDK_CONSTANTS.PRINT_WIDTH_DEFAULT,
        },
        fn: function (args, _a) {
            var indentation = _a.indentation, printWidth = _a.printWidth;
            return __awaiter(void 0, void 0, void 0, function () {
                var format, parsers;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0: return [4 /*yield*/, import("prettier/standalone")];
                        case 1:
                            format = (_b.sent()).format;
                            return [4 /*yield*/, import("prettier/parser-typescript")];
                        case 2:
                            parsers = (_b.sent()).parsers;
                            return [2 /*return*/, format(args, {
                                    parser: "typescript",
                                    plugins: [{ parsers: parsers, defaultOptions: { printWidth: printWidth } }],
                                    tabWidth: indentation,
                                })];
                    }
                });
            });
        },
    },
};
