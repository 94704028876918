import Drawer from "@/component/Drawer";
import LoadableLoading from "@/component/LoadableLoading";
import RootRedirect from "@/component/RootRedirect";
import Toolbar from "@/component/Toolbar";
import { appActions, useAppDispatch, useAppSelector } from "@/dependency/store";
import { CONSTANTS, isValidAppTheme } from "@/util";
import { useScreenBreakpoint } from "@misc-chatbot/common-ui";
import cn from "classnames";
import { useEffect } from "react";
import { useThemeSwitcher } from "react-css-theme-switcher";
import Loadable from "react-loadable";
import { Route, Switch } from "react-router-dom";
import "./style.less";

const MyPipelines = Loadable({
  loader: () => {
    return import("@/component/MyPipelines");
  },
  loading: LoadableLoading,
});

const Pipeline = Loadable({
  loader: () => {
    return import("@/component/Pipeline");
  },
  loading: LoadableLoading,
});

const QuickJavaScriptRuntime = Loadable({
  loader: () => {
    return import("@/component/QuickJavaScriptRuntime");
  },
  loading: LoadableLoading,
});

const CurrentPipelineRedirect = Loadable({
  loader: () => {
    return import("@/component/Pipeline/redirect");
  },
  loading: LoadableLoading,
});

const CLASS_BLOCK = "app-container";
const CLASS_ELEMENT_APP_CONTENT = `${CLASS_BLOCK}__app-content`;
const CLASS_ELEMENT_TOOLBAR = `${CLASS_BLOCK}__toolbar`;

export default function App() {
  const isPopconfirmVisible = useAppSelector(({ app }) => {
    return app.isPopconfirmVisible;
  });

  const { currentTheme } = useThemeSwitcher();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!isValidAppTheme(currentTheme)) {
      return;
    }

    dispatch(appActions.setCurrentTheme(currentTheme));
  }, [currentTheme, dispatch]);

  const screenBreakpoint = useScreenBreakpoint();

  useEffect(() => {
    dispatch(appActions.setScreenBreakpoint(screenBreakpoint));
  }, [dispatch, screenBreakpoint]);

  return (
    <div
      className={cn(
        CLASS_BLOCK,
        `${CLASS_BLOCK}_theme-${currentTheme}`,
        isPopconfirmVisible && `${CLASS_BLOCK}_dim`
      )}
    >
      <Toolbar
        className={cn(
          CLASS_ELEMENT_TOOLBAR,
          `${CLASS_ELEMENT_TOOLBAR}_theme-${currentTheme}`
        )}
      />

      <Switch>
        <Route path={CONSTANTS.ROUTE_MY_PIPELINES}>
          <MyPipelines className={CLASS_ELEMENT_APP_CONTENT} />
        </Route>

        <Route path={CONSTANTS.ROUTE_NEW_PIPELINE}>
          <CurrentPipelineRedirect className={CLASS_ELEMENT_APP_CONTENT} />
        </Route>

        <Route path={CONSTANTS.ROUTE_PIPELINE}>
          <Pipeline className={CLASS_ELEMENT_APP_CONTENT} />
        </Route>

        <Route path={CONSTANTS.ROUTE_QUICK_JAVASCRIPT_RUNTIME}>
          <QuickJavaScriptRuntime className={CLASS_ELEMENT_APP_CONTENT} />
        </Route>

        <Route>
          <RootRedirect />
        </Route>
      </Switch>

      <Drawer />
    </div>
  );
}
