import { memo, useEffect } from "react";
import { LoadingComponentProps } from "react-loadable";
import LoadingLogo from "../LoadingLogo";
import "./style.less";

const CLASS_BLOCK = "loadable-loading-container";

export function LoadableLoading({
  error,
  pastDelay,
  retry,
}: LoadingComponentProps) {
  useEffect(() => {
    if (error == null) {
      return;
    }

    retry();
  }, [error, retry]);

  if (!pastDelay) {
    return null;
  }

  return (
    <div className={CLASS_BLOCK}>
      <LoadingLogo />
    </div>
  );
}

export default memo(LoadableLoading);
