import cn from "classnames";
import "./style.less";

const CLASS_BLOCK = "loading-logo";

export default function LoadingLogo({
  className,
}: Readonly<{ className?: string }>) {
  return (
    <img
      className={cn(CLASS_BLOCK, className)}
      alt="logo"
      src="/image/logo.svg"
    />
  );
}
