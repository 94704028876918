import { appActions, useAppDispatch } from "@/dependency/store";
import { CONSTANTS } from "@/util";
import { useRequest } from "ahooks";
import { useEffect } from "react";
import { Redirect } from "react-router-dom";

export default function RootRedirect() {
  const {
    data: pipelineCount,
    error: countingError,
    loading: countingPipelines,
  } = useRequest(async () => {
    const { default: dependencyBuilder } = await import("@/dependency");
    const dependency = await dependencyBuilder.getDependency();
    return dependency.api.countPipelines();
  });

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (countingError == null) {
      return;
    }

    dispatch(appActions.triggerError(countingError));
  }, [countingError, dispatch]);

  if (countingPipelines) {
    return null;
  }

  if (pipelineCount === 0) {
    return (
      <Redirect
        to={`${CONSTANTS.ROUTE_NEW_PIPELINE}?${CONSTANTS.QUERY_PARAM_PIPELINE_REDIRECT_SAMPLE}=true`}
      />
    );
  } else {
    return <Redirect to={CONSTANTS.ROUTE_MY_PIPELINES} />;
  }
}
