import App from "@/component/App";
import { createStore, RootState } from "@/dependency/store";
import type { Action } from "@reduxjs/toolkit";
import { useRequest } from "ahooks";
import { ConnectedRouter, routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";
import React, { useEffect } from "react";
import {
  ThemeSwitcherProvider,
  useThemeSwitcher,
} from "react-css-theme-switcher";
import Loadable from "react-loadable";
import { Provider, ReactReduxContext } from "react-redux";
import { createEpicMiddleware } from "redux-observable";
import "./EntryPoint.less";
import type { IAppTheme, IDependency } from "./interface";
import { defaultAppTheme } from "./util";

const THEMES: Readonly<{ [K in IAppTheme]: string }> = {
  dark: `${process.env.PUBLIC_URL}/temp/antd.dark.min.css`,
  light: `${process.env.PUBLIC_URL}/temp/antd.min.css`,
};

interface IProps {
  readonly waitUntilThemeLoaded?: boolean;
}

function PrivateEntryPoint({ waitUntilThemeLoaded = true }: IProps = {}) {
  const { data, error } = useRequest(async () => {
    const history = createBrowserHistory();

    const [store] = await Promise.all([
      (async () => {
        const { createRootEpic } = await import("@/dependency/store");
        const { default: dependencyBuilder } = await import("@/dependency");
        const dependency = await dependencyBuilder.getDependency();

        const epicMiddleware = createEpicMiddleware<
          Action<string>,
          Action<string>,
          RootState,
          IDependency
        >({ dependencies: dependency });

        const store = await createStore({
          history,
          additionalMiddlewares: [epicMiddleware, routerMiddleware(history)],
        });

        const rootEpic = await createRootEpic();
        epicMiddleware.run(rootEpic);
        return store;
      })(),
      /**
       * Preload all components to reuse a single initial loading indicator,
       * instead of jumping around multiple loading indicators from Loadable
       * loading component.
       */
      Loadable.preloadAll(),
    ]);

    return { history, store };
  });

  const { status: loadThemeStatus } = useThemeSwitcher();

  const isFullyInitialized =
    data != null && (!waitUntilThemeLoaded || loadThemeStatus === "loaded");

  useEffect(() => {
    if (!isFullyInitialized) {
      return;
    }

    /**
     * This progress container is only to show some animation when the main js
     * files are being downloaded. When the app is fully initialized, we need
     * to remove it to make way for js-enabled progress indicated.
     */
    window.document.querySelector("#initial-progress-container")?.remove();
  }, [isFullyInitialized]);

  if (error != null) {
    return (
      <div
        style={{
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          height: "100%",
          padding: "1.6rem",
          width: "100%",
        }}
      >
        <span>Something went wrong with the page, please try reloading 😥</span>

        <span style={{ marginTop: "1.2rem" }}>
          Error message: {error.message}
        </span>
      </div>
    );
  }

  if (!isFullyInitialized) {
    return null;
  }

  return (
    <Provider store={data.store}>
      <ConnectedRouter context={ReactReduxContext} history={data.history}>
        <App />
      </ConnectedRouter>
    </Provider>
  );
}

export default function EntryPoint(props: IProps | undefined = undefined) {
  return (
    <React.StrictMode>
      <ThemeSwitcherProvider
        defaultTheme={defaultAppTheme}
        insertionPoint={document.querySelector("title")}
        themeMap={THEMES}
      >
        <PrivateEntryPoint waitUntilThemeLoaded={props?.waitUntilThemeLoaded} />
      </ThemeSwitcherProvider>
    </React.StrictMode>
  );
}
