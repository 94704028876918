import type { IPipelineWithTestInput } from "@/interface";
import { deepWritable } from "@haipham/javascript-helper-object";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { SortOrder } from "antd/lib/table/interface";

namespace IMyPipelinesFilter {
  export type Favorite = "onlyFavorites" | "showAll";
}

export type IMyPipelinesFilter = IMyPipelinesFilter.Favorite;

export interface IMyPipelinesSorter {
  readonly field: keyof Pick<IPipelineWithTestInput, "createdAt" | "name">;
  readonly order: NonNullable<SortOrder>;
}

export interface IMyPipelinesState {
  readonly filters: readonly IMyPipelinesFilter[];
  readonly isDeletingPipeline: boolean;
  readonly isExporting: boolean;
  readonly isImporting: boolean;
  readonly isInitialized: boolean;
  readonly isLoadingPipelines: boolean;
  readonly isTogglingFavorite: boolean;
  readonly pipelines: readonly IPipelineWithTestInput[];
  readonly searchQuery: string | undefined;
  readonly sorter: IMyPipelinesSorter | undefined;
}

const initialState: IMyPipelinesState = {
  filters: [],
  isDeletingPipeline: false,
  isExporting: false,
  isImporting: false,
  isInitialized: false,
  isLoadingPipelines: false,
  isTogglingFavorite: false,
  pipelines: [],
  searchQuery: undefined,
  sorter: undefined,
};

export const myPipelinesSlice = createSlice({
  initialState,
  name: "myPipelines",
  reducers: {
    initialize: () => {},
    deinitialize: () => {
      return initialState;
    },
    deletePipeline: (state, action: PayloadAction<string>) => {
      const index = state.pipelines.findIndex((pipeline) => {
        return pipeline.id === action.payload;
      });

      if (index < 0) {
        return;
      }

      state.pipelines.splice(index, 1);
    },
    setFilters: (state, action: PayloadAction<IMyPipelinesFilter[]>) => {
      state.filters = action.payload;
    },
    setIsDeletingPipeline: (state, action: PayloadAction<boolean>) => {
      state.isDeletingPipeline = action.payload;
    },
    setIsExporting: (state, action: PayloadAction<boolean>) => {
      state.isExporting = action.payload;
    },
    setIsImporting: (state, action: PayloadAction<boolean>) => {
      state.isImporting = action.payload;
    },
    setIsInitialized: (state, action: PayloadAction<boolean>) => {
      state.isInitialized = action.payload;
    },
    setIsLoadingPipelines: (state, action: PayloadAction<boolean>) => {
      state.isLoadingPipelines = action.payload;
    },
    setIsTogglingFavorite: (state, action: PayloadAction<boolean>) => {
      state.isTogglingFavorite = action.payload;
    },
    setMyPipelines: (
      state,
      action: PayloadAction<IMyPipelinesState["pipelines"]>
    ) => {
      state.pipelines = deepWritable(action.payload);
    },
    setSearchQuery: (state, action: PayloadAction<string>) => {
      state.searchQuery = action.payload;
    },
    setSorter: (state, action: PayloadAction<IMyPipelinesState["sorter"]>) => {
      state.sorter = action.payload;
    },
    updatePipeline: (state, action: PayloadAction<IPipelineWithTestInput>) => {
      const index = state.pipelines.findIndex((pipeline) => {
        return pipeline.id === action.payload.id;
      });

      if (index < 0) {
        return;
      }

      state.pipelines[index] = deepWritable(action.payload);
    },

    triggerPostInitialize: () => {},
    triggerDeletePipeline: (_state, _action: PayloadAction<string>) => {},
    triggerReloadMyPipelines: () => {},
    triggerToggleFavorite: (_state, _action: PayloadAction<string>) => {},
  },
});

export const myPipelinesActions = myPipelinesSlice.actions;
export default myPipelinesSlice.reducer;
