import type { IMyPipelinesFilter, IMyPipelinesState } from "@/dependency/store";
import { keyof, typedValue } from "@haipham/javascript-helper-object";
import { SDK_CONSTANTS } from "@swissknife/sdk/util";
import type { ButtonProps } from "antd";
import type { CSSProperties } from "react";

const _ERRORS = ["PIPELINE_NOT_FOUND"] as const;

const ERRORS = _ERRORS.reduce((acc, error) => {
  acc[`ERROR_${error}`] = error;
  return acc;
}, {} as Record<string, string>) as Readonly<{
  [K in typeof _ERRORS[number] as `ERROR_${K}`]: K;
}>;

export const CONSTANTS = {
  ...ERRORS,
  ...SDK_CONSTANTS,

  COL_PROPS_LABEL_FULL_WIDTH: { span: 24 },

  /**
   * If there are too many pipes, we will run into OOM, so in that case we shall
   * only calculate the final value instead of each intermediate value.
   */
  COUNT_MAX_INTERMEDIATE_PIPE_EVALUATION: 30,

  COUNT_SKELETON_LOADING_ROWS_DEFAULT: 10,

  DURATION_AUTOSAVE_MS: 1000,
  /**
   * If an API call is too fast, wait for some time so that the changes do not
   * feel janky.
   */
  DURATION_PREVENT_JANK_MS: 1000,
  DURATION_INPUT_DEBOUNCE_MS: 300,
  DURATION_TUTORIAL_DELAY_MS: 500,
  DURATION_WAIT_UNTIL_INTERVAL_MS: 500,
  DURATION_WAIT_UNTIL_TIMEOUT_MS: 5000,

  FORMAT_DATE: "DD MMM YYYY",

  ID_INITIAL_PIPELINE_INPUT: "initial",

  INDEX_ACTIVE_PIPE_RESULT_DEFAULT: -1,

  LENGTH_MAX_TOOLBAR_PIPELINE_NAME: 48,

  KEY_NAMESPACE: "namespace",
  KEY_STORAGE_APP_THEME: process.env.REACT_APP_KEY_STORAGE_APP_THEME,

  KEY_SIDE_BAR_INPUT_GENERATOR_MENU_ITEM_KEY: "generator",
  KEY_SIDE_BAR_PIPE_RECIPE_MENU_ITEM_KEY: "recipe",

  NAME_BUSINESS: "FluentUtils",

  PAGE_SIZE_PIPES: 10,

  PROPS_MODAL_NO_CANCEL_BUTTON: { style: { display: "none" } },

  PROPS_POP_CONFIRM_DELETE_CANCEL: typedValue<ButtonProps>({ size: "middle" }),
  PROPS_POP_CONFIRM_DELETE_OK: typedValue<ButtonProps>({
    danger: true,
    size: "middle",
  }),

  SEPARATOR_YAML: "---",

  SPACING_1_6_REM: 16,
  SPACING_1_2_REM: 12,

  SPACING_CARD_HEADER_HEIGHT: 40,

  STYLE_MODAL_BODY: typedValue<CSSProperties>({
    height: "70vh",
    width: "80vw",
  }),

  STYLE_MENU_ITEM: typedValue<CSSProperties>({
    marginLeft: "-0.8rem",
    marginRight: "-0.8rem",
    paddingRight: "0.8rem",
  }),

  TUTORIAL: {
    PIPELINE: {
      KEY_STORAGE_HAS_VIEWED_PIPELINE_BASIC_TUTORIALS:
        "hasViewedPipelineBasicTutorials",
      KEY_STORAGE_HAS_VIEWED_PIPELINE_FULL_DISPLAY_TUTORIALS:
        "hasViewedPipelineFullDisplayTutorials",
      KEY_STORAGE_HAS_VIEWED_PIPELINE_COMPACT_DISPLAY_TUTORIALS:
        "hasViewedPipelineCompactDisplayTutorials",
      KEY_STORAGE_HAS_VIEWED_PIPELINE_HIDDEN_SIDE_BAR_TUTORIALS:
        "hasViewedPipelineHiddenSideBarTutorials",
      KEY_STORAGE_HAS_VIEWED_PIPELINE_NOTHING_INPUT_TUTORIALS:
        "hasViewedPipelineNothingInputTutorials",
    },

    KEY_STORAGE_HAS_VIEWED_DRAWER_TUTORIALS: "hasViewedDrawerTutorials",
    KEY_STORAGE_HAS_VIEWED_MY_PIPELINE_TUTORIALS:
      "hasViewedMyPipelineTutorials",
    KEY_STORAGE_HAS_VIEWED_EXPORT_PIPELINES_TUTORIALS:
      "hasViewedExportPipelineTutorials",
    KEY_STORAGE_HAS_VIEWED_IMPORT_PIPELINES_TUTORIALS:
      "hasViewedImportPipelineTutorials",
  },

  QUERY_PARAM_IS_EDITING_PIPELINE: "isEditing",

  QUERY_PARAM_MY_PIPELINES_IS_EXPORTING:
    keyof<IMyPipelinesState>("isExporting"),
  QUERY_PARAM_MY_PIPELINES_IS_IMPORTING:
    keyof<IMyPipelinesState>("isImporting"),
  QUERY_PARAM_MY_PIPELINES_ONLY_FAVORITES:
    typedValue<IMyPipelinesFilter>("onlyFavorites"),
  QUERY_PARAM_MY_PIPELINES_SEARCH_QUERY:
    keyof<IMyPipelinesState>("searchQuery"),
  QUERY_PARAM_MY_PIPELINES_SHOW_ALL: typedValue<IMyPipelinesFilter>("showAll"),

  QUERY_PARAM_PIPELINE_REDIRECT_SAMPLE: "sample",

  QUERY_PARAM_PIPELINE_SHOW_RESULT_PREVIEW: "showResultPreview",

  ROUTE_MY_PIPELINES: "/pipelines" as const,
  ROUTE_NEW_PIPELINE: "/pipeline/new" as const,
  ROUTE_PIPELINE: "/pipeline/:id" as const,
  ROUTE_QUICK_JAVASCRIPT_RUNTIME: "/quick-javascript-runtime" as const,

  THEME_DEFAULT: process.env.REACT_APP_APP_THEME_DEFAULT,

  ZOOM_OPEN_STREET_VIEW_DEFAULT: 18,
};
