var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { createValidator } from "../util/createValidator";
import { t } from "../util/i18n";
import { PIPE_CATEGORIES } from "./category";
export var PIPES_OBJECT = {
    "count-object-keys": {
        category: PIPE_CATEGORIES.Object,
        name: t("Count object keys"),
        description: t("Count the number of keys in an object"),
        requireType: "object",
        returnType: "number",
        validateInput: createValidator("objectValidator"),
        fn: function (args) {
            return Object.keys(args).length;
        },
    },
    "delete-object-key": {
        category: PIPE_CATEGORIES.Object,
        name: t("Delete object key"),
        description: t("Delete a key from an object"),
        requireType: "object",
        returnType: "object",
        validateInput: createValidator("objectValidator"),
        defaultAdditionalArgs: { key: "" },
        fn: function (args, _a) {
            var key = _a.key;
            return __awaiter(void 0, void 0, void 0, function () {
                var cloneDeep, objectClone;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0: return [4 /*yield*/, import("lodash-es")];
                        case 1:
                            cloneDeep = (_b.sent()).cloneDeep;
                            objectClone = cloneDeep(args);
                            delete objectClone[key];
                            return [2 /*return*/, objectClone];
                    }
                });
            });
        },
    },
    "get-all-object-keys": {
        category: PIPE_CATEGORIES.Object,
        name: t("Get all object keys"),
        description: t("Get all the keys of an object"),
        requireType: "object",
        returnType: "array",
        validateInput: createValidator("objectValidator"),
        fn: function (args) {
            return Object.keys(args);
        },
    },
    "get-object-entries": {
        category: PIPE_CATEGORIES.Object,
        name: t("Get object entries"),
        description: t("Get all entries for an object, in the form of key-value tuples"),
        requireType: "object",
        returnType: "array",
        validateInput: createValidator("objectValidator"),
        fn: function (args) {
            return Object.entries(args);
        },
    },
    "get-object-property": {
        category: PIPE_CATEGORIES.Object,
        name: t("Get object property"),
        description: t([
            "Get an object property at a specified key. Nested property syntax ",
            'such as "a.b[0].c.d" is supported. For example, if the object is ',
            '"{ a: { b: { c: [1] } } }" and the key is "a.b.c[0]", the final ',
            "result would be 1.",
        ].join("")),
        requireType: "object",
        returnType: "any",
        validateInput: createValidator("objectValidator"),
        defaultAdditionalArgs: { key: "" },
        fn: function (args, _a) {
            var key = _a.key;
            return __awaiter(void 0, void 0, void 0, function () {
                var get;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0: return [4 /*yield*/, import("lodash-es")];
                        case 1:
                            get = (_b.sent()).get;
                            return [2 /*return*/, get(args, key)];
                    }
                });
            });
        },
    },
    "has-object-property": {
        category: PIPE_CATEGORIES.Object,
        name: t("Has object key"),
        description: t("Check if an object has a specified key"),
        requireType: "object",
        returnType: "boolean",
        validateInput: createValidator("objectValidator"),
        defaultAdditionalArgs: { key: "" },
        fn: function (args, _a) {
            var key = _a.key;
            return __awaiter(void 0, void 0, void 0, function () {
                var get;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0: return [4 /*yield*/, import("lodash-es")];
                        case 1:
                            get = (_b.sent()).get;
                            return [2 /*return*/, get(args, key) != null];
                    }
                });
            });
        },
    },
    "object-to-json": {
        category: PIPE_CATEGORIES.Object,
        name: t("Object to JSON"),
        description: t("Stringify an object into a JSON string"),
        requireType: "object",
        returnType: "string",
        validateInput: createValidator("objectValidator"),
        fn: function (args) {
            return JSON.stringify(args);
        },
    },
};
