import { useCallback, useEffect, useState } from "react";
import { Primitive } from "ts-essentials";

export function useDelay(
  milliseconds: number,
  { activate }: Readonly<{ activate?: boolean }> = {}
): boolean {
  const [finished, setFinished] = useState(false);

  useEffect(() => {
    if (activate === false) {
      return;
    }

    if (milliseconds <= 0) {
      setFinished(true);
      return;
    }

    setTimeout(() => {
      setFinished(true);
    }, milliseconds);
  }, [activate, milliseconds, setFinished]);

  return finished;
}

namespace IUsePairwiseState {
  namespace ISetNewState {
    export type IArgs<T> = T | ((newState: T) => T);
  }

  export type ISetNewState<T> = (args: ISetNewState.IArgs<T>) => void;
}

export function usePairwiseState<T extends Primitive | {}>(
  initialState: T
): [[T | undefined, T], IUsePairwiseState.ISetNewState<T>] {
  const [pair, setPair] = useState<[T | undefined, T]>([
    undefined,
    initialState,
  ]);

  const setNewState = useCallback<IUsePairwiseState.ISetNewState<T>>(
    (args) => {
      setPair((previousPair) => {
        let newState: T;

        if (typeof args === "function") {
          newState = args(previousPair[1]);
        } else {
          newState = args;
        }

        return [previousPair.at(-1), newState];
      });
    },
    [setPair]
  );

  return [pair, setNewState];
}
