import { CONSTANTS } from "./constant";
import { TimeoutError } from "./error";

export function waitUntil(
  predicate: () => Promise<boolean> | boolean,
  {
    customSetTimeout = (() => {
      if (global.window != null) {
        return global.window.setTimeout;
      } else {
        return global.setTimeout;
      }
    })(),
    interval = CONSTANTS.DURATION_WAIT_UNTIL_INTERVAL_MS,
    timeout = CONSTANTS.DURATION_WAIT_UNTIL_TIMEOUT_MS,
  }: Readonly<{
    customSetTimeout?: typeof window["setTimeout"];
    interval?: number;
    timeout?: number;
  }> = {}
): Promise<void> {
  let startTime = Date.now();

  return new Promise((resolve, reject) => {
    async function wait() {
      const elapsed = Date.now() - startTime;

      if (elapsed >= timeout) {
        reject(new TimeoutError());
        return;
      }

      const passedPredicate = await Promise.resolve(predicate());

      if (passedPredicate) {
        resolve(undefined);
      } else {
        customSetTimeout(wait, interval);
      }
    }

    wait();
  });
}
