var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { isKeyValueObject } from "@haipham/javascript-helper-object";
import { SDK_CONSTANTS } from "../util/constant";
function hasToString(args) {
    return typeof (args === null || args === void 0 ? void 0 : args.toString) === "function";
}
export function stringifyEvaluationResult(args) {
    if (typeof args === "bigint") {
        return args.toString();
    }
    if (Array.isArray(args) || isKeyValueObject(args)) {
        return JSON.stringify(args, function () {
            var _a = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                _a[_i] = arguments[_i];
            }
            var _b = __read(_a, 2), value = _b[1];
            if (typeof value === "bigint") {
                return value.toString();
            }
            return value;
        }, SDK_CONSTANTS.INDENTATION_COUNT_DEFAULT);
    }
    if (hasToString(args)) {
        return args.toString();
    }
    return String(args !== null && args !== void 0 ? args : null);
}
