var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { typedValue } from "@haipham/javascript-helper-object";
import { createValidator } from "../util/createValidator";
import { t } from "../util/i18n";
import { PIPE_CATEGORIES } from "./category";
var ADDITIONAL_ARGS_REG_EXP_DEFAULT = typedValue({
    pattern: "",
    global: false,
    ignoreCase: false,
    multiline: false,
    unicode: false,
});
function getRegExpFlags(_a) {
    var global = _a.global, ignoreCase = _a.ignoreCase, multiline = _a.multiline, unicode = _a.unicode;
    return [
        [global, "g"],
        [ignoreCase, "i"],
        [multiline, "m"],
        [unicode, "u"],
    ].reduce(function (acc, _a) {
        var _b = __read(_a, 2), enabled = _b[0], flag = _b[1];
        if (enabled) {
            acc = "".concat(acc).concat(flag);
        }
        return acc;
    }, "");
}
export var PIPES_REG_EXP = {
    "match-string-regexp": {
        category: PIPE_CATEGORIES["Regular Expression"],
        name: t("Match string"),
        description: t("Match a string with a Regular Expression and return the match results"),
        requireType: "string",
        returnType: "object",
        validateInput: createValidator("stringValidator"),
        defaultAdditionalArgs: ADDITIONAL_ARGS_REG_EXP_DEFAULT,
        fn: function (args, _a) {
            var _b;
            var pattern = _a.pattern, additionalArgs = __rest(_a, ["pattern"]);
            var flags = getRegExpFlags(additionalArgs);
            var regexp = new RegExp(pattern, flags);
            var matchResult = args.match(regexp);
            var groups;
            var matches;
            if (matchResult != null) {
                groups = (_b = matchResult.groups) !== null && _b !== void 0 ? _b : {};
                delete matchResult["groups"];
                matches = matchResult;
            }
            else {
                groups = {};
                matches = [];
            }
            return { groups: groups, matches: matches };
        },
    },
    "replace-string-regexp": {
        category: PIPE_CATEGORIES["Regular Expression"],
        name: t("Replace string"),
        description: t("Replace a string using Regular Expression"),
        requireType: "string",
        returnType: "string",
        validateInput: createValidator("stringValidator"),
        defaultAdditionalArgs: __assign(__assign({}, ADDITIONAL_ARGS_REG_EXP_DEFAULT), { replaceString: "" }),
        fn: function (args, _a) {
            var pattern = _a.pattern, replaceString = _a.replaceString, additionalArgs = __rest(_a, ["pattern", "replaceString"]);
            var flags = getRegExpFlags(additionalArgs);
            var regexp = new RegExp(pattern, flags);
            return args.replace(regexp, replaceString);
        },
    },
};
