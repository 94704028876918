import cn from "classnames";
import "./style.less";

const CLASS_BLOCK = "my-pipelines-toolbar-element-container";

export default function MyPipelineToolbarDynamicElement({
  className,
}: Readonly<{ className?: string }>) {
  return <div className={cn(CLASS_BLOCK, className)}></div>;
}
