import { isKeyValueObject, keyof } from "@haipham/javascript-helper-object";
import type { IPipe } from "@swissknife/sdk";

export function normalizeAdditionalArgumentKeys<T>(
  additionalArgs: T
): readonly [string, unknown][] {
  const normalized: Record<string, unknown> = {};
  const baseKey = keyof<IPipe>("additionalArgs");

  if (isKeyValueObject(additionalArgs)) {
    for (const [argKey, argValue] of Object.entries(additionalArgs)) {
      normalized[`${baseKey}.${argKey}`] = argValue;
    }
  } else {
    normalized[baseKey] = additionalArgs;
  }

  return Object.entries(normalized);
}
