var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { PIPES_BY_TYPE as SDK_PIPES_BY_TYPE } from "@swissknife/sdk";
import { PIPES_DOCUMENT } from "./_pipe/document";
import { PIPES_GEOGRAPHY } from "./_pipe/geography";
import { PIPES_HTML } from "./_pipe/html";
import { PIPES_OBJECT } from "./_pipe/object";
export var PIPES_BY_TYPE = __assign(__assign(__assign(__assign(__assign({}, SDK_PIPES_BY_TYPE), PIPES_DOCUMENT), PIPES_GEOGRAPHY), PIPES_HTML), PIPES_OBJECT);
