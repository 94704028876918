import type {
  IPipeFormItemManualTransformation,
  IPipeInputGenerator,
  IPipelineWithTestInput,
  ISpecialResultConfiguration,
} from "@/interface";
import { CONSTANTS } from "@/util";
import { requireNotNull } from "@protoman92/precondition";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type {
  IEvaluationResult,
  IPipeRecipe,
  IPipeWithUIStates,
} from "@swissknife/ui-sdk";
import { PIPES_BY_TYPE } from "@swissknife/ui-sdk";
import { FormInstance } from "antd";
import cuid from "cuid";
import { set as lodashSet } from "lodash-es";

export interface IPipelineState
  extends Pick<
    IPipelineWithTestInput,
    | "autofilterSelectablePipes"
    | "displayMode"
    | "hideSideBar"
    | "isFavorited"
    | "renderPreview"
    | "testInput"
    | "wrapFinalResultContent"
  > {
  readonly activePipeResultIndex: number;
  readonly activeSideBarInputGeneratorKeys: string[];
  readonly activeSideBarPipeKeys: string[];
  readonly activeSideBarPipeRecipeKeys: string[];
  readonly evaluatingPipeRecipe: boolean;
  /** If there are no pipes, this will be shown */
  readonly initialEvaluationResult: IEvaluationResult<unknown> | undefined;
  readonly isEditingSettings: boolean;
  readonly isEvaluatingInputGenerator: boolean;
  readonly isEvaluatingResult: boolean;
  readonly isInitialized: boolean;
  readonly isLoadingPipeline: boolean;
  readonly isTogglingFavorite: boolean;
  readonly pipelineID: string | undefined;
  readonly pipelineName: string;
  readonly selectedPipes: IPipeWithUIStates[];
  readonly sidebarSearchQuery: string;
  readonly showResultPreview: boolean;
  readonly specialResultConfiguration: ISpecialResultConfiguration | undefined;

  readonly formInstanceMethods:
    | Pick<FormInstance, "resetFields" | "setFieldsValue">
    | undefined;
}

const initialState: IPipelineState = {
  activePipeResultIndex: CONSTANTS.INDEX_ACTIVE_PIPE_RESULT_DEFAULT,
  activeSideBarInputGeneratorKeys: [],
  activeSideBarPipeKeys: [],
  activeSideBarPipeRecipeKeys: [],
  autofilterSelectablePipes: true,
  displayMode: "full",
  evaluatingPipeRecipe: false,
  hideSideBar: false,
  initialEvaluationResult: undefined,
  isEditingSettings: false,
  isEvaluatingResult: false,
  isFavorited: false,
  isInitialized: false,
  isLoadingPipeline: false,
  isEvaluatingInputGenerator: false,
  isTogglingFavorite: false,
  pipelineID: undefined,
  pipelineName: "",
  renderPreview: undefined,
  showResultPreview: false,
  sidebarSearchQuery: "",
  selectedPipes: [],
  specialResultConfiguration: undefined,
  testInput: "",
  wrapFinalResultContent: false,

  formInstanceMethods: undefined,
};

function selectPipeByType(
  state: IPipelineState,
  pipeInformation:
    | IPipelineState["selectedPipes"][number]["type"]
    | Pick<
        IPipelineState["selectedPipes"][number],
        "additionalArgs" | "disabled" | "type"
      >
): void {
  let disabled = false;
  let type: IPipeWithUIStates["type"];
  let additionalArgs: IPipeWithUIStates["additionalArgs"] | undefined;

  if (typeof pipeInformation === "string") {
    type = pipeInformation;
    additionalArgs = undefined;
  } else {
    disabled = pipeInformation.disabled;
    type = pipeInformation.type;
    additionalArgs = pipeInformation.additionalArgs;
  }

  const partialType: Omit<typeof state["selectedPipes"][number], "type"> = {
    disabled,
    id: cuid(),
    additionalArgs: undefined,
    evaluationResult: undefined,
    loading: false,
  };

  const pipe = { ...partialType, type };

  if (additionalArgs != null) {
    pipe.additionalArgs = additionalArgs;
  } else {
    const actualPipe = PIPES_BY_TYPE[pipe.type];
    pipe.additionalArgs = actualPipe.defaultAdditionalArgs;
  }

  /**
   * It's an unfortunate type cast, but it seems Typescript cannot handle too
   * many types in a union. As a result, the type property raises an error.
   */
  state.selectedPipes.push(pipe as typeof state["selectedPipes"][number]);
}

export const pipelineSlice = createSlice({
  initialState,
  name: "pipeline",
  reducers: {
    initialize: () => {},
    deinitialize: () => {
      return initialState;
    },
    noop: () => {},
    clearSelectedPipes: (state) => {
      state.selectedPipes = [];
    },
    rearrangeByID: (
      state,
      action: PayloadAction<
        Pick<IPipelineState["selectedPipes"][number], "id"> &
          Readonly<{ delta: number }>
      >
    ) => {
      const index = state.selectedPipes.findIndex((pipe) => {
        return pipe.id === action.payload.id;
      });

      const newIndex = index + action.payload.delta;

      if (newIndex < 0 || newIndex >= state.selectedPipes.length) {
        return;
      }

      const [pipe] = state.selectedPipes.splice(index, 1);
      state.selectedPipes.splice(newIndex, 0, requireNotNull(pipe));
    },
    setActiveSideBarInputGeneratorKeys: (
      state,
      action: PayloadAction<IPipelineState["activeSideBarInputGeneratorKeys"]>
    ) => {
      state.activeSideBarInputGeneratorKeys = action.payload;
    },
    setActiveSideBarPipeKeys: (
      state,
      action: PayloadAction<IPipelineState["activeSideBarPipeKeys"]>
    ) => {
      state.activeSideBarPipeKeys = action.payload;
    },
    setActiveSideBarPipeRecipeKeys: (
      state,
      action: PayloadAction<IPipelineState["activeSideBarPipeKeys"]>
    ) => {
      state.activeSideBarPipeRecipeKeys = action.payload;
    },
    setAutofilterSelectablePipes: (state, action: PayloadAction<boolean>) => {
      state.autofilterSelectablePipes = action.payload;
    },
    setEvaluatingPipeRecipe: (state, action: PayloadAction<boolean>) => {
      state.evaluatingPipeRecipe = action.payload;
    },
    setPipeline: (state, action: PayloadAction<IPipelineWithTestInput>) => {
      state.autofilterSelectablePipes = Boolean(
        action.payload.autofilterSelectablePipes
      );

      state.displayMode = action.payload.displayMode;
      state.hideSideBar = Boolean(action.payload.hideSideBar);
      state.isFavorited = action.payload.isFavorited;
      state.pipelineID = action.payload.id;
      state.pipelineName = action.payload.name;
      state.renderPreview = Boolean(action.payload.renderPreview);
      state.testInput = action.payload.testInput;

      state.wrapFinalResultContent = Boolean(
        action.payload.wrapFinalResultContent
      );

      /**
       * Reset the pipes here so that hot reload does not add new pipes
       * erroneously.
       */
      state.selectedPipes = [];

      for (const pipe of action.payload.pipes) {
        selectPipeByType(state, pipe);
      }
    },
    setDisplayMode: (
      state,
      action: PayloadAction<IPipelineState["displayMode"]>
    ) => {
      state.displayMode = action.payload;
    },
    setFormValues: (state, action: PayloadAction<Record<string, unknown>>) => {
      for (const key in action.payload) {
        const value = action.payload[key];

        /** If the key contains dots, the first component is the pipe ID */
        if (!key.includes(".")) {
          lodashSet(state, key, value);
        } else {
          const [pipeID, ...keyComponents] = key.split(".");

          const pipe = state.selectedPipes.find((pipe) => {
            return pipe.id === pipeID;
          });

          if (pipe == null) {
            return;
          }

          lodashSet(pipe, keyComponents, value);
        }
      }
    },
    setEvaluationResultByID: (
      state,
      action: PayloadAction<
        Pick<IPipelineState["selectedPipes"][number], "id" | "evaluationResult">
      >
    ) => {
      const pipe = state.selectedPipes.find((_pipe) => {
        return _pipe.id === action.payload.id;
      });

      if (pipe == null) {
        return;
      }

      pipe.evaluationResult = action.payload.evaluationResult;
    },
    setInitialEvaluationResult: (
      state,
      action: PayloadAction<IEvaluationResult>
    ) => {
      state.initialEvaluationResult = action.payload;
    },
    setIsEditingSettings: (state, action: PayloadAction<boolean>) => {
      state.isEditingSettings = action.payload;
    },
    setIsEvaluatingInputGenerator: (state, action: PayloadAction<boolean>) => {
      state.isEvaluatingInputGenerator = action.payload;
    },
    setIsEvaluatingResult: (state, action: PayloadAction<boolean>) => {
      state.isEvaluatingResult = action.payload;
    },
    setIsFavorited: (state, action: PayloadAction<boolean>) => {
      state.isFavorited = action.payload;
    },
    setIsInitialized: (state, action: PayloadAction<boolean>) => {
      state.isInitialized = action.payload;
    },
    setIsLoadingPipeline: (state, action: PayloadAction<boolean>) => {
      state.isLoadingPipeline = action.payload;
    },
    setIsTogglingFavorite: (state, action: PayloadAction<boolean>) => {
      state.isTogglingFavorite = action.payload;
    },
    setLoadingPipeResultByID: (
      state,
      action: PayloadAction<
        Pick<IPipelineState["selectedPipes"][number], "id" | "loading">
      >
    ) => {
      const pipe = state.selectedPipes.find((_pipe) => {
        return _pipe.id === action.payload.id;
      });

      if (pipe == null) {
        return;
      }

      pipe.loading = action.payload.loading;
    },
    selectPipeByType: (
      state,
      action: PayloadAction<Parameters<typeof selectPipeByType>[1]>
    ) => {
      return selectPipeByType(state, action.payload);
    },
    selectPipesByTypes: (
      state,
      action: PayloadAction<readonly Parameters<typeof selectPipeByType>[1][]>
    ) => {
      for (const pipeInformation of action.payload) {
        selectPipeByType(state, pipeInformation);
      }
    },
    deselectPipeByID: (
      state,
      action: PayloadAction<IPipelineState["selectedPipes"][number]["id"]>
    ) => {
      const index = state.selectedPipes.findIndex((pipe) => {
        return pipe.id === action.payload;
      });

      if (index < 0) {
        return;
      }

      state.selectedPipes.splice(index, 1);
    },
    removeSpecialResultConfiguration: (state) => {
      state.specialResultConfiguration = undefined;
    },
    setHideSideBar: (state, action: PayloadAction<boolean>) => {
      state.hideSideBar = action.payload;
    },
    setShowResultPreview: (state, action: PayloadAction<boolean>) => {
      state.showResultPreview = action.payload;
    },
    setSidebarSearchQuery: (state, action: PayloadAction<string>) => {
      state.sidebarSearchQuery = action.payload;
    },
    setSpecialResultConfiguration: (
      state,
      action: NonNullable<
        PayloadAction<IPipelineState["specialResultConfiguration"]>
      >
    ) => {
      state.specialResultConfiguration = action.payload;
    },
    toggleDisabledByID: (
      state,
      action: PayloadAction<IPipelineState["selectedPipes"][number]["id"]>
    ) => {
      const pipe = state.selectedPipes.find((_pipe) => {
        return _pipe.id === action.payload;
      });

      if (pipe == null) {
        return;
      }

      pipe.disabled = !pipe.disabled;
    },
    triggerPostInitialize: (
      _state,
      _action: PayloadAction<
        Readonly<{
          pipelineID: NonNullable<IPipelineState["pipelineID"]>;
        }>
      >
    ) => {},
    triggerInputGenerator: (
      _state,
      _action: PayloadAction<IPipeInputGenerator["type"]>
    ) => {},
    triggerPipeRecipe: (
      _state,
      _action: PayloadAction<IPipeRecipe["type"]>
    ) => {},
    triggerPipelineEvaluation: (_state) => {},
    triggerResetTutorials: () => {},
    triggerSavePipeline: () => {},
    triggerSetFormFieldsValue: (
      _state,
      _action: PayloadAction<Record<string, unknown>>
    ) => {},
    triggerToggleFavorite: () => {},
    triggerTransformFormItemManually: (
      _state,
      _action: PayloadAction<IPipeFormItemManualTransformation>
    ) => {},

    setFormInstanceMethods: (
      state,
      action: PayloadAction<IPipelineState["formInstanceMethods"]>
    ) => {
      state.formInstanceMethods = action.payload;
    },
  },
});

export function getLastActiveEvaluationResult({
  activePipeResultIndex,
  initialEvaluationResult,
  selectedPipes,
}: Pick<
  IPipelineState,
  "activePipeResultIndex" | "initialEvaluationResult" | "selectedPipes"
>): IEvaluationResult | undefined {
  return (
    selectedPipes.at(activePipeResultIndex)?.evaluationResult ??
    initialEvaluationResult
  );
}

export function getLastEnabledPipeIndex(
  { selectedPipes }: Pick<IPipelineState, "selectedPipes">,
  endExclusiveIndex: number = selectedPipes.length
): number {
  for (let index = endExclusiveIndex - 1; index >= 0; index -= 1) {
    if (!selectedPipes[index]?.disabled) {
      return index;
    }
  }

  return -1;
}

export const pipelineActions = pipelineSlice.actions;
export default pipelineSlice.reducer;
