import { DeepWritable } from "ts-essentials";

export * from "./constant";
export * from "./error";
export * from "./hooks";
export * from "./i18n";
export * from "./inputGenerator";
export * from "./pipe";
export * from "./rxjs";
export * from "./theme";
export * from "./waitUntil";

export function asyncTimeout(durationMs: number): Promise<void> {
  return new Promise((resolve) => {
    if (global.window != null) {
      global.window.setTimeout(() => {
        resolve(undefined);
      }, durationMs);
    } else {
      global.setTimeout(() => {
        resolve(undefined);
      }, durationMs);
    }
  });
}

export function ellipsize(text: string, maxChar: number): string {
  const newText = text.slice(0, maxChar);

  if (text === newText) {
    return text;
  }

  return `${newText.trim()}...`;
}

export function mutateDirectly<T>(
  args: T,
  modifier: (args2: DeepWritable<T>) => void
): T {
  modifier(args as DeepWritable<T>);
  return args;
}
