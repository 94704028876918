export var PIPE_CATEGORIES = {
    Array: "Array",
    Auth: "Auth",
    Boolean: "Boolean",
    Color: "Color",
    CSV: "CSV",
    Database: "Database",
    Date: "Date",
    Docker: "Docker",
    Document: "Document",
    Encoding: "Encoding",
    General: "General",
    HTML: "HTML",
    Infrastructure: "Infrastructure",
    Identifier: "Identifier",
    Javascript: "Javascript",
    JSON: "JSON",
    Kubernetes: "Kubernetes",
    Markdown: "Markdown",
    Math: "Math",
    MySQL: "MySQL",
    Network: "Network",
    Number: "Number",
    Object: "Object",
    "Programming Language": "Programming Language",
    Redis: "Redis",
    "Regular Expression": "Regular Expression",
    Stream: "Stream",
    String: "String",
    Typescript: "Typescript",
    URL: "URL",
    YAML: "YAML",
};
