import { t } from "@swissknife/sdk/util/i18n";
import { PIPE_CATEGORIES } from "./category";
export var PIPES_GEOGRAPHY = {
    "get-current-coordinates": {
        category: PIPE_CATEGORIES.Geography,
        name: t("Get current coordinates"),
        description: t("Get your current coordinates"),
        requiresBrowserAPI: true,
        requireType: "nothing",
        returnType: "object",
        fn: function () {
            if (window.navigator.geolocation == null) {
                throw new Error(t("Your browser does not support geolocation"));
            }
            return new Promise(function (resolve, reject) {
                window.navigator.geolocation.getCurrentPosition(function (position) {
                    resolve({
                        accuracy: position.coords.accuracy,
                        altitude: position.coords.altitude,
                        altitudeAccuracy: position.coords.altitudeAccuracy,
                        heading: position.coords.heading,
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude,
                        speed: position.coords.speed,
                    });
                }, function (error) {
                    reject(error);
                });
            });
        },
    },
};
