var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import { keyof } from "@haipham/javascript-helper-object";
import { createValidator } from "../util/createValidator";
import { t } from "../util/i18n";
import { PIPE_CATEGORIES } from "./category";
export var PIPES_DOCUMENT = {
    "compare-documents": {
        category: PIPE_CATEGORIES.Document,
        name: t("Compare documents"),
        description: t("Compare two documents to find the differences"),
        requireType: "string",
        returnType: "array",
        validateInput: createValidator("stringValidator"),
        defaultAdditionalArgs: { documentToCompare: "", showOnlyDiff: false },
        fn: function (args, _a) {
            var documentToCompare = _a.documentToCompare, showOnlyDiff = _a.showOnlyDiff;
            return __awaiter(void 0, void 0, void 0, function () {
                var fastDiff, changes;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0: return [4 /*yield*/, import("fast-diff")];
                        case 1:
                            fastDiff = (_b.sent()).default;
                            changes = fastDiff(args, documentToCompare).map(function (_a) {
                                var _b = __read(_a, 2), result = _b[0], value = _b[1];
                                switch (result) {
                                    case 0: {
                                        return { value: value, count: value.length };
                                    }
                                    case 1: {
                                        return { value: value, added: true, count: value.length };
                                    }
                                    case -1: {
                                        return { value: value, removed: true, count: value.length };
                                    }
                                }
                            });
                            if (showOnlyDiff) {
                                changes = changes.filter(function (change) {
                                    return change.added || change.removed;
                                });
                            }
                            return [2 /*return*/, changes];
                    }
                });
            });
        },
    },
    "compare-documents-by-sentences": {
        category: PIPE_CATEGORIES.Document,
        name: t("Compare documents by sentences"),
        description: t([
            "Compare two documents' sentences to find the differences. Performance ",
            "is not as good as ".concat(keyof("compare-documents"), "."),
        ].join("")),
        requireType: "string",
        returnType: "array",
        validateInput: createValidator("stringValidator"),
        defaultAdditionalArgs: { documentToCompare: "", showOnlyDiff: false },
        fn: function (args, _a) {
            var documentToCompare = _a.documentToCompare, showOnlyDiff = _a.showOnlyDiff;
            return __awaiter(void 0, void 0, void 0, function () {
                var diffSentences, changes;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0: return [4 /*yield*/, import("diff")];
                        case 1:
                            diffSentences = (_b.sent()).diffSentences;
                            changes = diffSentences(args, documentToCompare);
                            if (showOnlyDiff) {
                                changes = changes.filter(function (change) {
                                    return change.added || change.removed;
                                });
                            }
                            return [2 /*return*/, changes];
                    }
                });
            });
        },
    },
    "count-words": {
        category: PIPE_CATEGORIES.Document,
        name: t("Count words"),
        description: t("Count the number of words in a document"),
        requireType: "string",
        returnType: "number",
        validateInput: createValidator("stringValidator"),
        fn: function (args) { return __awaiter(void 0, void 0, void 0, function () {
            var words;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, import("lodash-es")];
                    case 1:
                        words = (_a.sent()).words;
                        return [2 /*return*/, words(args).length];
                }
            });
        }); },
    },
    "count-word-frequency": {
        category: PIPE_CATEGORIES.Document,
        name: t("Count word frequency"),
        description: t("Count the frequencies of words in a document"),
        requireType: "string",
        returnType: "array",
        validateInput: createValidator("stringValidator"),
        fn: function (args) { return __awaiter(void 0, void 0, void 0, function () {
            var _a, groupBy, words, allWords, groupedWords, frequencyTuples, word;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, import("lodash-es")];
                    case 1:
                        _a = _b.sent(), groupBy = _a.groupBy, words = _a.words;
                        allWords = words(args);
                        groupedWords = groupBy(allWords);
                        frequencyTuples = [];
                        for (word in groupedWords) {
                            frequencyTuples.push([word, groupedWords[word].length]);
                        }
                        return [2 /*return*/, frequencyTuples];
                }
            });
        }); },
    },
    "document-diffs-to-html": {
        category: PIPE_CATEGORIES.Document,
        name: t("Document diffs to HTML"),
        description: t("Covert a document diff Array to HTML for easy visualization"),
        requireType: "array",
        returnType: "string",
        validateInput: createValidator("documentDiffValidator"),
        fn: function (args) { return __awaiter(void 0, void 0, void 0, function () {
            var htmlElements, args_1, args_1_1, diff, htmlElement;
            var e_1, _a;
            return __generator(this, function (_b) {
                htmlElements = [];
                try {
                    for (args_1 = __values(args), args_1_1 = args_1.next(); !args_1_1.done; args_1_1 = args_1.next()) {
                        diff = args_1_1.value;
                        htmlElement = void 0;
                        switch (true) {
                            case diff.added: {
                                htmlElement = "<span class=\"added\" style=\"color:green;text-decoration:underline;\">".concat(diff.value, "</span>");
                                break;
                            }
                            case diff.removed: {
                                htmlElement = "<span class=\"removed\" style=\"color:red;text-decoration:line-through;\">".concat(diff.value, "</span>");
                                break;
                            }
                            default: {
                                htmlElement = "<span>".concat(diff.value, "</span>");
                                break;
                            }
                        }
                        htmlElements.push(htmlElement);
                    }
                }
                catch (e_1_1) { e_1 = { error: e_1_1 }; }
                finally {
                    try {
                        if (args_1_1 && !args_1_1.done && (_a = args_1.return)) _a.call(args_1);
                    }
                    finally { if (e_1) throw e_1.error; }
                }
                htmlElements.unshift("<span style=\"white-space:pre;\">");
                htmlElements.push("</span>");
                return [2 /*return*/, htmlElements.join("")];
            });
        }); },
    },
    "sort-word-frequency": {
        category: PIPE_CATEGORIES.Document,
        name: t("Sort word frequency counts"),
        description: t("Sort an Array of word frequency counts in ascending or descending order"),
        requireType: "array",
        returnType: "array",
        validateInput: createValidator("wordFrequencyValidator"),
        defaultAdditionalArgs: { order: "descending" },
        fn: function (args, _a) {
            var order = _a.order;
            return __awaiter(void 0, void 0, void 0, function () {
                var multiplier;
                return __generator(this, function (_b) {
                    switch (order) {
                        case "ascending": {
                            multiplier = 1;
                            break;
                        }
                        case "descending": {
                            multiplier = -1;
                            break;
                        }
                    }
                    return [2 /*return*/, args.slice().sort(function (_a, _b) {
                            var _c = __read(_a, 2), lhs = _c[1];
                            var _d = __read(_b, 2), rhs = _d[1];
                            return (lhs - rhs) * multiplier;
                        })];
                });
            });
        },
    },
    "split-into-words": {
        category: PIPE_CATEGORIES.Document,
        name: t("Split into words"),
        description: t("Split a document into an Array of words"),
        requireType: "string",
        returnType: "array",
        validateInput: createValidator("stringValidator"),
        fn: function (args) { return __awaiter(void 0, void 0, void 0, function () {
            var words;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, import("lodash-es")];
                    case 1:
                        words = (_a.sent()).words;
                        return [2 /*return*/, words(args)];
                }
            });
        }); },
    },
};
