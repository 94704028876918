import type { AppEpic } from "@/dependency/store";
import type { Action } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";
import { MonoTypeOperatorFunction } from "rxjs";
import { filter, map, switchMap, takeUntil } from "rxjs/operators";

export function combineEpicsWithLifecycle({
  epics,
  filterStartAction,
  filterEndAction,
}: Readonly<{
  epics: readonly AppEpic[];
  filterStartAction: (action: Action<unknown>) => boolean;
  filterEndAction: (action: Action<unknown>) => boolean;
}>): AppEpic {
  const combined = combineEpics(...epics);

  return ((): AppEpic => {
    return (...args) => {
      return args[0].pipe(
        filter(filterStartAction),
        switchMap(() => {
          return combined(...args).pipe(
            takeUntil(args[0].pipe(filter(filterEndAction)))
          );
        })
      );
    };
  })();
}

export function noopOperator<T>(): MonoTypeOperatorFunction<T> {
  return map((args) => {
    return args;
  });
}
