var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import { toArray } from "@haipham/javascript-helper-array";
import { isKeyValueObjectType, keyofArray } from "@haipham/javascript-helper-object";
import { isValidReturnType } from "@swissknife/sdk";
import { groupBy, partition } from "lodash-es";
import { PIPES_BY_TYPE } from "./pipesByType";
import { PIPE_CATEGORIES } from "./_pipe/category";
export { isValidRegExpMatchResult, stringifyEvaluationResult } from "@swissknife/sdk";
export * from "./pipesByType";
export * from "./_pipe/category";
var PRIORITY_PIPE_CATEGORIES = [
    PIPE_CATEGORIES.Stream,
    PIPE_CATEGORIES.General,
];
var PIPE_CATEGORIES_SORT_MAP = __spreadArray(__spreadArray([], __read(PRIORITY_PIPE_CATEGORIES), false), __read(Object.values(PIPE_CATEGORIES)
    .filter(function (category) {
    return !PRIORITY_PIPE_CATEGORIES.includes(category);
})
    .sort(function (lhs, rhs) {
    return lhs.localeCompare(rhs);
})), false).reduce(function (acc, category, index) {
    acc[category] = index;
    return acc;
}, {});
export var PIPES = Object.entries(PIPES_BY_TYPE)
    .map(function (_a) {
    var _b = __read(_a, 2), pipeType = _b[0], pipe = _b[1];
    return __assign(__assign({}, pipe), { type: pipeType });
})
    .sort(function (lhs, rhs) {
    /** Category could be empty */
    var lhsFirst = PIPE_CATEGORIES_SORT_MAP[toArray(lhs.category)[0]] || 0;
    var rhsFirst = PIPE_CATEGORIES_SORT_MAP[toArray(rhs.category)[0]] || 0;
    return lhsFirst - rhsFirst;
});
export var PIPES_BY_CATEGORY = (function () {
    var e_1, _a;
    var result = {};
    var _b = __read(partition(PIPES, function (pipe) {
        return toArray(pipe.category).length === 0;
    }), 2), topLevelPipes = _b[0], pipesWithCategories = _b[1];
    var pipesToGroup = __spreadArray(__spreadArray([], __read(topLevelPipes), false), __read(pipesWithCategories), false);
    try {
        for (var pipesToGroup_1 = __values(pipesToGroup), pipesToGroup_1_1 = pipesToGroup_1.next(); !pipesToGroup_1_1.done; pipesToGroup_1_1 = pipesToGroup_1.next()) {
            var pipe = pipesToGroup_1_1.value;
            var intermediate = result;
            var categories = toArray(pipe.category);
            for (var index = 0; index < categories.length; index += 1) {
                var category = categories[index];
                if (intermediate[category] == null) {
                    intermediate[category] = {};
                }
                intermediate = intermediate[category];
            }
            intermediate[pipe.type] = pipe;
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (pipesToGroup_1_1 && !pipesToGroup_1_1.done && (_a = pipesToGroup_1.return)) _a.call(pipesToGroup_1);
        }
        finally { if (e_1) throw e_1.error; }
    }
    return result;
})();
export var PIPE_TYPES = new Set(Object.keys(groupBy(PIPES, keyofArray(PIPES, "type"))));
export function isValidPipeType(args) {
    return typeof args === "string" && PIPE_TYPES.has(args);
}
export var isValidPipe = (function () {
    var validators = {
        name: {
            validator: function () {
                var _a = [];
                for (var _i = 0; _i < arguments.length; _i++) {
                    _a[_i] = arguments[_i];
                }
                var _b = __read(_a, 2), value = _b[1];
                return typeof value === "string";
            },
        },
        returnType: {
            validator: function () {
                var _a = [];
                for (var _i = 0; _i < arguments.length; _i++) {
                    _a[_i] = arguments[_i];
                }
                var _b = __read(_a, 2), value = _b[1];
                return isValidReturnType(value);
            },
        },
        type: {
            validator: function () {
                var _a = [];
                for (var _i = 0; _i < arguments.length; _i++) {
                    _a[_i] = arguments[_i];
                }
                var _b = __read(_a, 2), value = _b[1];
                return isValidPipeType(value);
            },
        },
    };
    return function (args) {
        return isKeyValueObjectType(args, validators);
    };
})();
