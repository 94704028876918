import { hydrate, render } from "react-dom";
import EntryPoint from "./EntryPoint";
import "./polyfill";
import reportWebVitals from "./reportWebVitals";

const entrypoint = <EntryPoint />;
const rootElement = document.getElementById("root");

if (rootElement?.hasChildNodes()) {
  hydrate(entrypoint, rootElement);
} else {
  render(entrypoint, rootElement);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
