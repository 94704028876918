import { t } from "@/util";
import { useDelay, usePairwiseState } from "@/util/hooks";
import { useLocalStorageState } from "ahooks";
import { Button, Tooltip } from "antd";
import { TooltipPlacement } from "antd/lib/tooltip";
import cn from "classnames";
import { CSSProperties, memo, useCallback, useEffect, useState } from "react";
import "./style.less";

const CLASS_BLOCK = "tutorial-view-container";
const CLASS_ELEMENT_ACTIVE_TUTORIAL = `${CLASS_BLOCK}__active-tutorial`;

export const CLASS_ELEMENT_TUTORIAL_VIEW_TOOLTIP = `${CLASS_BLOCK}__tooltip`;
export const CLASS_ELEMENT_TUTORIAL_VIEW_TUTORIAL_PLACEHOLDER = `${CLASS_BLOCK}__tutorial`;

export interface ITutorial {
  message: string;
  /** The placement of the tutorial tooltip, defaults to "right" */
  placement?: TooltipPlacement;
  /**
   * The selector to search for the target node to place the tutorial tooltip.
   * Note that not every element is eligible to be a tutorial target. It must:
   * - Not have inline "z-index" style(s).
   * - Work with z-index (i.e. "positioned" elements or flex items): https://www.w3schools.com/cssref/pr_pos_z-index.asp
   */
  querySelector: string;
}

function getTutorialPosition(tutorial: ITutorial):
  | Readonly<{
      node: HTMLElement;
      position: DOMRect;
      tutorial: typeof tutorial;
    }>
  | undefined {
  const node = document.querySelector<HTMLElement>(tutorial.querySelector);

  if (node == null) {
    return undefined;
  }

  return { node, tutorial, position: node.getBoundingClientRect() };
}

export function TutorialView({
  className,
  delayMs = 0,
  persistenceKey,
  style,
  tutorials,
  visible = true,
  onTutorialsViewed,
}: Readonly<{
  className?: string;
  /**
   * Sometimes the tutorial targets appear too fast, so we can introduce a small
   * delay to prevent jankiness.
   */
  delayMs?: number;
  /**
   * The key to set in local storage after tutorials have been viewed or
   * skipped. Once it's set to true, the tutorials will not show up next time.
   */
  persistenceKey: string;
  style?: CSSProperties;
  tutorials: readonly ITutorial[];
  visible?: boolean;
  /**
   * When the tutorials have been viewed, either in the current session or a
   * previous one, this callback will be invoked. Make sure to memoize it with
   * useCallback if the parent component uses some complex state to control
   * multiple tutorial views at once; otherwise an infinite loop may result.
   */
  onTutorialsViewed?: () => void;
}>) {
  const [initialized, setInitialized] = useState(false);

  const containerRef = useCallback(
    (node: HTMLDivElement | null) => {
      if (node == null) {
        return;
      }

      setInitialized(true);
    },
    [setInitialized]
  );

  const [[previousActiveIndex, activeIndex], setActiveIndex] =
    usePairwiseState<number>(0);

  useEffect(() => {
    if (!initialized) {
      return;
    }

    let previousTutorial: typeof tutorials[number] | undefined;
    let previousPosition: ReturnType<typeof getTutorialPosition> | undefined;

    if (
      previousActiveIndex != null &&
      (previousTutorial = tutorials.at(previousActiveIndex)) != null &&
      (previousPosition = getTutorialPosition(previousTutorial)) != null
    ) {
      previousPosition.node.classList.remove(CLASS_ELEMENT_ACTIVE_TUTORIAL);
    }

    const activeTutorial = tutorials.at(activeIndex);
    let activePosition: ReturnType<typeof getTutorialPosition> | undefined;

    if (activeTutorial != null) {
      if ((activePosition = getTutorialPosition(activeTutorial)) != null) {
        activePosition.node.classList.add(CLASS_ELEMENT_ACTIVE_TUTORIAL);
      } else {
        setActiveIndex(activeIndex + 1);
      }
    }
  }, [
    activeIndex,
    initialized,
    previousActiveIndex,
    setActiveIndex,
    tutorials,
  ]);

  const [hasViewedTutorial, setHasViewedTutorial] = useLocalStorageState(
    persistenceKey,
    {
      deserializer: (value) => {
        return value === "true";
      },
    }
  );

  useEffect(() => {
    if (!initialized || activeIndex < tutorials.length) {
      return;
    }

    setHasViewedTutorial(true);
  }, [activeIndex, initialized, setHasViewedTutorial, tutorials]);

  useEffect(() => {
    /**
     * Since local storage is not reactive, once we encounter
     * hasViewedTutorial=true, we can immediately notify onTutorialsViewed.
     * This way, onTutorialsViewed will always be called when tutorials have
     * been viewed - whether in the current or in a previous session.
     */
    if (!hasViewedTutorial) {
      return;
    }

    onTutorialsViewed?.call(undefined);
  }, [hasViewedTutorial, onTutorialsViewed]);

  const finishedDelay = useDelay(delayMs, { activate: visible && initialized });

  if (!visible || hasViewedTutorial) {
    return null;
  }

  return (
    <div
      className={cn(
        CLASS_BLOCK,
        finishedDelay && `${CLASS_BLOCK}_finished-delay`,
        className
      )}
      ref={containerRef}
      style={style}
      onClick={(event) => {
        event.stopPropagation();

        setActiveIndex((previousState) => {
          return previousState + 1;
        });
      }}
    >
      {finishedDelay &&
        (() => {
          const tutorial = tutorials.at(activeIndex);
          let tutorialPosition: ReturnType<typeof getTutorialPosition>;

          if (
            tutorial == null ||
            (tutorialPosition = getTutorialPosition(tutorial)) == null
          ) {
            return null;
          }

          const {
            position,
            tutorial: { message, placement = "right" },
          } = tutorialPosition;

          return (
            <Tooltip
              className={CLASS_ELEMENT_TUTORIAL_VIEW_TOOLTIP}
              placement={placement}
              title={message}
              visible
            >
              <div
                className={CLASS_ELEMENT_TUTORIAL_VIEW_TUTORIAL_PLACEHOLDER}
                style={{
                  height: position.height,
                  left: position.left,
                  top: position.top,
                  width: position.width,
                }}
              />
            </Tooltip>
          );
        })()}

      <Button
        className={`${CLASS_BLOCK}__skip`}
        type="primary"
        onClick={(event) => {
          event.stopPropagation();
          setHasViewedTutorial(true);
        }}
      >
        {t("Skip tutorials")}
      </Button>
    </div>
  );
}

export default memo(TutorialView);
