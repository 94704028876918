var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { createValidator } from "../util/createValidator";
import { t } from "../util/i18n";
import { PIPE_CATEGORIES } from "./category";
export var PIPES_COLOR = {
    "hex-to-rgba-object": {
        category: PIPE_CATEGORIES.Color,
        name: t("HEX to RGBA object"),
        description: t([
            "Convert a HEX value to a RGBA object that contains values for red, ",
            "green, blue and alpha",
        ].join("")),
        requireType: "string",
        returnType: "object",
        validateInput: createValidator("stringValidator"),
        fn: function (args) { return __awaiter(void 0, void 0, void 0, function () {
            var hexToRGB;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, import("hex-rgb")];
                    case 1:
                        hexToRGB = (_a.sent()).default;
                        return [2 /*return*/, hexToRGB(args)];
                }
            });
        }); },
    },
    "rgba-object-to-hex": {
        category: PIPE_CATEGORIES.Color,
        name: t("RGBA object to HEX"),
        description: t("Convert a RGBA object to a HEX value"),
        requireType: "object",
        returnType: "string",
        validateInput: createValidator("rgbaObjectValidator"),
        fn: function (_a) {
            var red = _a.red, green = _a.green, blue = _a.blue, alpha = _a.alpha;
            return __awaiter(void 0, void 0, void 0, function () {
                var rgbaToHex;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0: return [4 /*yield*/, import("rgb-hex")];
                        case 1:
                            rgbaToHex = (_b.sent()).default;
                            return [2 /*return*/, rgbaToHex(red, green, blue, alpha)];
                    }
                });
            });
        },
    },
    "rgba-object-to-rgba-css": {
        category: PIPE_CATEGORIES.Color,
        name: t("RGBA object to RGBA CSS"),
        description: t([
            "Convert a RGBA object to a RGBA CSS string in the format ",
            "rgba(r, g, b, a)",
        ].join("")),
        requireType: "object",
        returnType: "string",
        validateInput: createValidator("rgbaObjectValidator"),
        fn: function (_a) {
            var red = _a.red, green = _a.green, blue = _a.blue, alpha = _a.alpha;
            return __awaiter(void 0, void 0, void 0, function () {
                return __generator(this, function (_b) {
                    return [2 /*return*/, "rgba(".concat(red, ", ").concat(green, ", ").concat(blue, ", ").concat(alpha, ")")];
                });
            });
        },
    },
    "rgba-css-to-hex": {
        category: PIPE_CATEGORIES.Color,
        name: t("RGBA CSS to HEX"),
        description: t([
            "Convert a RGBA CSS value, in the format rgba(r, g, b, a), to a HEX ",
            "value",
        ].join("")),
        requireType: "string",
        returnType: "string",
        validateInput: createValidator("rgbaCSSValidator"),
        fn: function (args) { return __awaiter(void 0, void 0, void 0, function () {
            var rgbaToHex;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, import("rgb-hex")];
                    case 1:
                        rgbaToHex = (_a.sent()).default;
                        return [2 /*return*/, rgbaToHex(args)];
                }
            });
        }); },
    },
    "rgba-css-to-rgba-object": {
        category: PIPE_CATEGORIES.Color,
        name: t("RGBA CSS to RGBA object"),
        description: t([
            "Convert a RGBA CSS value, in the format rgba(r, g, b, a), to a RGBA ",
            "object which has separate red, green, blue and alpha properties",
        ].join("")),
        requireType: "string",
        returnType: "object",
        validateInput: createValidator("rgbaCSSValidator"),
        fn: function (args) { return __awaiter(void 0, void 0, void 0, function () {
            var rgbaToHex, hexToRGBA, hexValue;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, import("rgb-hex")];
                    case 1:
                        rgbaToHex = (_a.sent()).default;
                        return [4 /*yield*/, import("hex-rgb")];
                    case 2:
                        hexToRGBA = (_a.sent()).default;
                        hexValue = rgbaToHex(args);
                        return [2 /*return*/, hexToRGBA(hexValue, { format: "object" })];
                }
            });
        }); },
    },
};
