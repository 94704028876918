if (Array.prototype.at == null) {
  /* eslint-disable no-extend-native */
  Array.prototype.at = function (index) {
    return this.slice(index)[0];
  };
}

(async () => {
  /** Taken from https://github.com/juggle/resize-observer */
  if ("ResizeObserver" in global.window === false) {
    const module = await import("@juggle/resize-observer");
    window.ResizeObserver = module.ResizeObserver;
  }
})();

const _ = {};
export default _;
