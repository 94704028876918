var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { SDK_CONSTANTS } from "../util/constant";
import { t } from "../util/i18n";
import { PIPE_CATEGORIES } from "./category";
export var PIPES_DOCKER = {
    "back-up-docker-image-command": {
        category: [PIPE_CATEGORIES.Infrastructure, PIPE_CATEGORIES.Docker],
        name: t("Back up image command"),
        description: t("Get a command to back up a Docker image from one registry to another"),
        requireType: "nothing",
        returnType: "string",
        defaultAdditionalArgs: {
            imageName: "nginx",
            sourceRegistry: SDK_CONSTANTS.DOCKER_REGISTRY_DEFAULT,
            destRegistry: SDK_CONSTANTS.DOCKER_REGISTRY_DEFAULT,
        },
        fn: function () {
            var _a = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                _a[_i] = arguments[_i];
            }
            var _b = __read(_a, 2), _c = _b[1], imageName = _c.imageName, sourceRegistry = _c.sourceRegistry, destRegistry = _c.destRegistry;
            return "\ndocker pull ".concat(sourceRegistry, "/").concat(imageName, ";\ndocker tag ").concat(sourceRegistry, "/").concat(imageName, " ").concat(destRegistry, "/").concat(imageName, ";\ndocker push ").concat(destRegistry, "/").concat(imageName, ";\n      ").trim();
        },
    },
};
