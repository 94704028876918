import myPipelinesReducer from "@/component/MyPipelines/store/slice";
import pipelineReducer from "@/component/Pipeline/store/slice";
import type { IDependency } from "@/interface";
import type { Promised } from "@haipham/javascript-helper-essential-types";
import type { Action, Middleware, ThunkAction } from "@reduxjs/toolkit";
import { configureStore } from "@reduxjs/toolkit";
import { connectRouter } from "connected-react-router";
import type { History } from "history";
import type { TypedUseSelectorHook } from "react-redux";
import { useDispatch, useSelector } from "react-redux";
import { combineEpics, Epic } from "redux-observable";
import appReducer from "./appSlice";

export * from "@/component/MyPipelines/store/slice";
export * from "@/component/Pipeline/store/slice";
export * from "./appSlice";

export async function createRootEpic(): Promise<AppEpic> {
  const { default: $appEpic } = await import("./appEpic");

  const { default: $myPipelinesEpic } = await import(
    "@/component/MyPipelines/store/epic"
  );

  const { default: $selectedPipesEpic } = await import(
    "@/component/Pipeline/store/epic"
  );

  return combineEpics($appEpic, $myPipelinesEpic, $selectedPipesEpic);
}

export async function createStore({
  additionalMiddlewares,
  history,
}: Readonly<{
  additionalMiddlewares?: Middleware[];
  history: History;
}>) {
  const store = configureStore({
    reducer: {
      app: appReducer,
      myPipelines: myPipelinesReducer,
      pipeline: pipelineReducer,
      router: connectRouter(history),
    },
    middleware: additionalMiddlewares,
  });

  return store;
}

export type IStore = Promised<ReturnType<typeof createStore>>;
export type AppDispatch = IStore["dispatch"];
export type RootState = ReturnType<IStore["getState"]>;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export type AppEpic = Epic<
  Action<string>,
  Action<string>,
  RootState,
  IDependency
>;

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => {
  return useDispatch<AppDispatch>();
};

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
