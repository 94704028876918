import { getDisplayModeDescription } from "@/component/Pipeline/util";
import {
  pipelineActions,
  useAppDispatch,
  useAppSelector,
} from "@/dependency/store";
import { CONSTANTS, ellipsize, t } from "@/util";
import { StarFilled, StarOutlined } from "@ant-design/icons";
import { AVAILABLE_DISPLAY_MODES } from "@swissknife/ui-sdk";
import { Button, Select, Typography } from "antd";
import cn from "classnames";
import "./style.less";

const CLASS_BLOCK = "pipeline-toolbar-element-container";
const CLASS_ELEMENT_CHILD = `${CLASS_BLOCK}__child`;

export const CLASS_ELEMENT_TOOLBAR_CHANGE_DISPLAY_MODE = `${CLASS_BLOCK}__display-mode`;
export const CLASS_ELEMENT_TOOLBAR_EDIT_SETTINGS = `${CLASS_BLOCK}__edit-settings`;
export const CLASS_ELEMENT_TOOLBAR_FAVORITE = `${CLASS_BLOCK}__favorite`;

export default function PipelineToolbarDynamicElement({
  className,
}: Readonly<{ className?: string }>) {
  const {
    displayMode,
    isFavorited,
    isLoadingPipeline,
    isTogglingFavorite,
    pipelineName,
  } = useAppSelector(({ pipeline }) => {
    return {
      pipelineName: pipeline.pipelineName,
      displayMode: pipeline.displayMode,
      isFavorited: pipeline.isFavorited,
      isLoadingPipeline: pipeline.isLoadingPipeline,
      isTogglingFavorite: pipeline.isTogglingFavorite,
    };
  });

  const dispatch = useAppDispatch();

  return (
    <div className={cn(CLASS_BLOCK, className)}>
      <Typography.Text
        className={cn(CLASS_ELEMENT_CHILD, `${CLASS_BLOCK}__pipeline-name`)}
        strong
      >
        {ellipsize(pipelineName, CONSTANTS.LENGTH_MAX_TOOLBAR_PIPELINE_NAME)}
      </Typography.Text>

      <Select
        className={cn(
          CLASS_ELEMENT_CHILD,
          CLASS_ELEMENT_TOOLBAR_CHANGE_DISPLAY_MODE
        )}
        value={displayMode}
        onChange={(newDisplayMode) => {
          dispatch(pipelineActions.setDisplayMode(newDisplayMode));
        }}
      >
        {AVAILABLE_DISPLAY_MODES.map((displayMode) => {
          return (
            <Select.Option key={displayMode} value={displayMode}>
              {getDisplayModeDescription(displayMode)}
            </Select.Option>
          );
        })}
      </Select>

      <Button
        className={cn(CLASS_ELEMENT_CHILD, CLASS_ELEMENT_TOOLBAR_EDIT_SETTINGS)}
        onClick={(event) => {
          event.stopPropagation();
          dispatch(pipelineActions.setIsEditingSettings(true));
        }}
      >
        {t("Settings")}
      </Button>

      <Button
        className={cn(CLASS_ELEMENT_CHILD, CLASS_ELEMENT_TOOLBAR_FAVORITE)}
        danger={isFavorited}
        disabled={isLoadingPipeline || isTogglingFavorite}
        loading={isLoadingPipeline || isTogglingFavorite}
        type="primary"
        onClick={(event) => {
          event.stopPropagation();
          dispatch(pipelineActions.triggerToggleFavorite());
        }}
      >
        {isFavorited ? (
          <>
            <span>{t("Favorited")}</span>
            <StarFilled className={`${CLASS_BLOCK}__favorited-icon`} />
          </>
        ) : (
          <>
            <span>{t("Favorite")}</span>
            <StarOutlined />
          </>
        )}
      </Button>
    </div>
  );
}
