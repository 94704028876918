import { appActions, useAppDispatch, useAppSelector } from "@/dependency/store";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { Button } from "antd";
import cn from "classnames";
import { memo } from "react";
import DynamicElement from "./DynamicElement";
import "./style.less";

const CLASS_BLOCK = "toolbar-container";

export function Toolbar({ className }: Readonly<{ className?: string }>) {
  const dispatch = useAppDispatch();

  const isDrawerOpen = useAppSelector(({ app }) => {
    return app.isDrawerOpen;
  });

  return (
    <div className={cn(CLASS_BLOCK, className)}>
      <Button
        onClick={(event) => {
          event.stopPropagation();
          dispatch(appActions.toggleIsDrawerOpen());
        }}
      >
        {isDrawerOpen ? <MenuFoldOutlined /> : <MenuUnfoldOutlined />}
      </Button>

      <DynamicElement className={`${CLASS_BLOCK}__dynamic-elements`} />
    </div>
  );
}

export default memo(Toolbar);
