var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import dayjs from "dayjs";
import dayjsCustomParseFormat from "dayjs/plugin/customParseFormat";
import dayjsUTCPlugin from "dayjs/plugin/utc";
import { PIPES_ARRAY } from "./pipe/array";
import { PIPES_AUTH } from "./pipe/auth";
import { PIPES_BOOLEAN } from "./pipe/boolean";
import { PIPES_COLOR } from "./pipe/color";
import { PIPES_CSV } from "./pipe/csv";
import { PIPES_CUSTOM } from "./pipe/custom";
import { PIPES_DATE } from "./pipe/date";
import { PIPES_DOCKER } from "./pipe/docker";
import { PIPES_DOCUMENT } from "./pipe/document";
import { PIPES_ENCODING } from "./pipe/encoding";
import { PIPES_GENERAL } from "./pipe/general";
import { PIPES_HTML } from "./pipe/html";
import { PIPES_IDENTIFIER } from "./pipe/identifier";
import { PIPES_JAVASCRIPT } from "./pipe/javascript";
import { PIPES_JSON } from "./pipe/json";
import { PIPES_KUBERNETES } from "./pipe/kubernetes";
import { PIPES_MARKDOWN } from "./pipe/markdown";
import { PIPES_MATH } from "./pipe/math";
import { PIPES_MYSQL } from "./pipe/mysql";
import { PIPES_NETWORK } from "./pipe/network";
import { PIPES_NUMBER } from "./pipe/number";
import { PIPES_OBJECT } from "./pipe/object";
import { PIPES_REDIS } from "./pipe/redis";
import { PIPES_REG_EXP } from "./pipe/regexp";
import { PIPES_STREAM } from "./pipe/stream";
import { PIPES_STRING } from "./pipe/string";
import { PIPES_TYPESCRIPT } from "./pipe/typescript";
import { PIPES_URL } from "./pipe/url";
import { PIPES_YAML } from "./pipe/yaml";
export * from "./interface";
export * from "./pipe/category";
export * from "./util";
dayjs.extend(dayjsCustomParseFormat);
dayjs.extend(dayjsUTCPlugin);
export var PIPES_BY_TYPE = __assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign({}, PIPES_ARRAY), PIPES_AUTH), PIPES_BOOLEAN), PIPES_COLOR), PIPES_CSV), PIPES_CUSTOM), PIPES_DATE), PIPES_DOCKER), PIPES_DOCUMENT), PIPES_ENCODING), PIPES_GENERAL), PIPES_HTML), PIPES_IDENTIFIER), PIPES_JAVASCRIPT), PIPES_JSON), PIPES_KUBERNETES), PIPES_MARKDOWN), PIPES_MATH), PIPES_MYSQL), PIPES_NETWORK), PIPES_NUMBER), PIPES_OBJECT), PIPES_REDIS), PIPES_REG_EXP), PIPES_STREAM), PIPES_STRING), PIPES_TYPESCRIPT), PIPES_URL), PIPES_YAML);
export function isValidReturnType(args) {
    if (typeof args !== "string") {
        return false;
    }
    switch (args) {
        case "any":
        case "array":
        case "boolean":
        case "bigint":
        case "date":
        case "number":
        case "object":
        case "stream":
        case "string":
            return true;
    }
}
